////////////////////////////////////////////////////////
// page_account [scss] 어카운트 관련 스타일을 정의하는 문서입니다.
////////////////////////////////////////////////////////


#ProfileInitPage{

    width: 100vw;
    height: 100vh;
    background-color: #222;
    display: flex;
    
    
    .InfoSide{
        height: 100%;
        width: 0%;
        background-color: orange;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        p{
            font-size: 90px;
            color: white;
            font-family: '프리텐다드Black';
            letter-spacing:5px;
            margin: 20px;
        }

    }
    .SettingSide{
        color: white;
        height: 100%;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .SetField{
            width: 300px;
            // height: 200px;
            padding: 0px 0px 40px 0px;
            // background-color: red;
            display: flex;
            flex-direction: column;

            align-items: center;
            .fieldTitle{
                width: 300px;
                text-align: center;
                margin-bottom: 20px;
            }
        }
        .FieldMargin{
            height: 20%;
            width: 100%;
        }
        .SetFieldList{
            height: 60%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .buttonList{
            height: 20%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: flex-end;
        }
        .profileImage{
            width: 100px;
            height: 100px;
            border-radius: 100px;
            background-color: #333;
            box-shadow:  10px 10px 20px #1d1d1d,
            -10px -10px 20px #272727;
            outline: none;
            border: none;
            transition: all 0.5s;
            &.lodingFalse{
                background-color: #333;
                width: 0px;
                padding: 50px;
                height: 0px;
            }
        }
        .name{
            background-color: #333;
            box-shadow:  10px 10px 20px #1d1d1d,
            -10px -10px 20px #272727;
            border-radius: 50px;
            outline: none;
            border: none;
            width: 300px;
            height: 50px;
            color: white;
            font-size: 30px;
            text-align: center;
            font-family: '프리텐다드SemiBold';
        }
     
        .summit{
            width: 300px;
            height: 70px;
            text-align: center;
            // border-radius: 45px;
            border-radius: 11px;
            background: #444;
            // background: #333;
            // box-shadow:  10px 10px 20px #1d1d1d,
            //              -10px -10px 20px #272727;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-family: '프리텐다드SemiBold';
        }
    }

}