.MiniView{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}
.MiniViewCover{
    position: absolute;
    width: 100%;
    height: 100%;   
}
.MiniViewScreen{
    background-color: white;
}
.MiniViewloding{
    width: 100%;
    height: 100%;
    background-color: #444;
}
.hover{

}