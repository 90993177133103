// 프리셋
p,h3,pre{
    margin: 0;
}

.NewsCaption_preset_normal{    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .NewsHeader{
        height: 25%;
        div{
            box-sizing: border-box;
            padding: 0px 10px;
        }
  
    }
    .NewsBody{
        height: 75%;
        pre{
            box-sizing: border-box;
            padding: 0px 10px;
        }

    }
}
