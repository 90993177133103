// 프리셋
p,h3,pre{
    margin: 0;
}

.DraftPick_preset_BusinessCard{    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    .outLine{
        width: 100%;
        height: 100%;
        background-color: white;
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        flex-direction: column;
        .inLine{
            width: 100%;
            height: 80%;
            background-color: orange;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            
            .member{
                width: 45%;
                background-color: white;
                // border: solid 3px red;
                height: 20%;
                margin: 2% 0%;
                display: flex;
                justify-content: space-between;
                .memberPosition{
                    width: 10%;
                    img{
                        width: 80%;
                        padding: 0% 10%;
                        height: 100%;
                        object-fit: scale-down;
                    }
                }
                .memberInfo{
                    display: flex;
                    width: 90%;
                    justify-content: space-between;
                    .memberName{
                        text-align: left;
                        width: 60%;
                        height: 100%;
                        overflow: hidden;
                        img{
                            height: 80%;
                        }
                    }
                    .memberMemo{
                        text-align: right;
                        width: 40%;
                        height: 100%;
                        line-height: 100%;
                        overflow: hidden;
                    }
                }

            }
        }
        .bottom{
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: space-between;
            .teamPoint{
                background-color: #444;
                width: 40%;
                height: 80%;
                margin: 2% 0;
                border-radius: 5000px;
                text-align: center;
                color: white;
                overflow: hidden;
            }
            .teamName{
                overflow: hidden;
                width: 60%;
            }
        }
    }
}
