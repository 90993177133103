/////////////////////////////////////////
// 기본형 모든포지션
/////////////////////////////////////////
.OverwatchRank_preset_normal{
    display: flex;
    background-color: #222;
    border-radius: 10px !important;
    color: #fff;
    overflow: hidden;
    font-weight: bold;
    flex-direction: column;
    .userName{
        width: 98%;
        margin: auto;
    }
    .userRank{
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .userName{
            width: 80%;
            margin: auto 10%;
        }
        .userRanklist{
            width: 33%;
            flex: 1;
            .tierImg{
                object-fit: scale-down;
                width: auto;
                height: 100%;  
            }
            div{
                display: flex;
                justify-content: space-between;
                width: 90%;
                height: 80%;
                margin: auto 5%;
                border-radius: 10px;
                span{
                    margin: 0px 5%;
                }
            }
            // background-color: blue;
        }
        .bronze{
            background-color: #1F4E79;
        }
        .silver{
            background-color: #585858;
        }
        .gold{
            background-color: #ad7117;
        }
        .platinum{
            background-color: #828282;
        }
        .diamond{
            background-color: #1F4E79;
        }
        .master{
            background: #F2994A; /* fallback for old browsers */
            background: -webkit-radial-gradient(circle, #F2C94C, #F2994A); /* Chrome 10-25, Safari 5.1-6 */
            background: radial-gradient(circle, #F2C94C, #F2994A); 
        }
        .grandmaster{
            background: #6D6027; /* fallback for old browsers */
            background: -webkit-radial-gradient(circle, #6D6027, #D3CBB8);  /* Chrome 10-25, Safari 5.1-6 */
            background: radial-gradient(circle, #6D6027, #D3CBB8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            text-shadow: 5% 5% 5% #222;
        }

        .ranker{
            background-color: #1F4E79;
        }

            li{
                
                display: flex;
            }
            .positionIcon{
                filter: invert(100%) ;
            }
            .tierIcon{

            }
    }
    .profilePrivate, .profileError, .profileLode{
        text-align: center;
        background-color: #585858;
        padding: 0% 5%;
        height: 100%;
    }

}
/////////////////////////////////////////
// 기본형 한포지션
/////////////////////////////////////////
.OverwatchRank_preset_normalShort{
    display: flex;
    background-color: #222;
    border-radius: 10px !important;
    color: #fff;
    overflow: hidden;
    font-weight: bold;
    flex-direction: column;
    .userName{
        width: 98%;
        margin: auto;
    }
    .userRank{
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .userName{
            width: 80%;
            margin: auto 10%;
            overflow: hidden;
        }
        .userRanklist{
            width: 90%;
            display: flex;
            justify-content: space-around;
            flex: 1;
            .positionImg{
                width: 12.5%;
                height: 80%; 
                margin: auto 0%;
                filter: invert(100%);
            }
            .tierImg{
                object-fit: scale-down;
                width: auto;
                height: 100%;   
            }
            div{
                display: flex;
                justify-content: space-between;
                width: 77.5%;
                height: 80%;
                margin: auto 0%;
                border-radius: 10px;
                span{
                    margin: 0px 5%;
                }
            }
            // background-color: blue;
        }
        .bronze{
            background-color: #1F4E79;
        }
        .silver{
            background-color: #585858;
        }
        .gold{
            background-color: #ad7117;
        }
        .platinum{
            background-color: #828282;
        }
        .diamond{
            background-color: #1F4E79;
        }
        .master{
            background: #F2994A; /* fallback for old browsers */
            background: -webkit-radial-gradient(circle, #F2C94C, #F2994A); /* Chrome 10-25, Safari 5.1-6 */
            background: radial-gradient(circle, #F2C94C, #F2994A); 
        }
        .grandmaster{
            background: #6D6027; /* fallback for old browsers */
            background: -webkit-radial-gradient(circle, #6D6027, #D3CBB8);  /* Chrome 10-25, Safari 5.1-6 */
            background: radial-gradient(circle, #6D6027, #D3CBB8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            text-shadow: 5% 5% 5% #222;
        }

        .ranker{
            background-color: #1F4E79;
        }

            li{
                
                display: flex;
            }
            .positionIcon{
                filter: invert(100%) ;
            }
            .tierIcon{

            }
    }
    .profilePrivate, .profileError, .profileLode{
        text-align: center;
        background-color: #585858;
        padding: 0% 5%;
        height: 100%;
    }

}
/////////////////////////////////////////
// 심플스타일 모든포지션
/////////////////////////////////////////
.OverwatchRank_preset_simple{
    display: flex;
    background-color: #222;
    border-radius: 10px !important;
    color: #fff;
    overflow: hidden;
    font-weight: bold;
    flex-direction: column;

    .userRank{
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .userName{
            width: 80%;
            margin: auto 10%;
        }
        .userRanklist{
            width: 33%;
            flex: 1;
            .tierImg{
                object-fit: scale-down;
                width: auto;
                height: 100%;  
            }
            div{
                display: flex;
                justify-content: space-between;
                width: 90%;
                height: 80%;
                margin: auto 5%;
                border-radius: 10px;
                span{
                    margin: 0px 5%;
                }
            }
            // background-color: blue;
        }
        .bronze{
            background-color: #1F4E79;
        }
        .silver{
            background-color: #585858;
        }
        .gold{
            background-color: #ad7117;
        }
        .platinum{
            background-color: #828282;
        }
        .diamond{
            background-color: #1F4E79;
        }
        .master{
            background: #F2994A; /* fallback for old browsers */
            background: -webkit-radial-gradient(circle, #F2C94C, #F2994A); /* Chrome 10-25, Safari 5.1-6 */
            background: radial-gradient(circle, #F2C94C, #F2994A); 
        }
        .grandmaster{
            background: #6D6027; /* fallback for old browsers */
            background: -webkit-radial-gradient(circle, #6D6027, #D3CBB8);  /* Chrome 10-25, Safari 5.1-6 */
            background: radial-gradient(circle, #6D6027, #D3CBB8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            text-shadow: 5% 5% 5% #222;
        }

        .ranker{
            background-color: #1F4E79;
        }

            li{
                
                display: flex;
            }
            .positionIcon{
                filter: invert(100%) ;
            }
            .tierIcon{
             object-fit: scale-down;
                width: auto;
                height: 100%;  
            }
    }
    .profilePrivate, .profileError, .profileLode{
        text-align: center;
        background-color: #585858;
        padding: 0% 5%;
        height: 100%;
    }

}
/////////////////////////////////////////
// 심플스타일 한포지션
/////////////////////////////////////////
.OverwatchRank_preset_simpleShort{
    display: flex;
    background-color: #222;
    border-radius: 10px !important;
    color: #fff;
    overflow: hidden;
    font-weight: bold;
    flex-direction: column;
    .userName{
        width: 98%;
        margin: auto;
    }

    .userRank{
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .userName{
            width: 80%;
            margin: auto 10%;
        }
        .userRanklist{
            width: 90%;
            display: flex;
            justify-content: space-around;
            flex: 1;
            .positionImg{
                width: 12.5%;
                height: 80%; 
                margin: auto 0%;
                filter: invert(100%);
            }
            .tierImg{
                object-fit: scale-down;
                width: auto;
                height: 100%;  
            }
            div{
                display: flex;
                justify-content: space-between;
                width: 77.5%;
                height: 80%;
                margin: auto 0%;
                border-radius: 10px;
                span{
                    margin: 0px 5%;
                }
            }
            // background-color: blue;
        }
        .bronze{
            background-color: #1F4E79;
        }
        .silver{
            background-color: #585858;
        }
        .gold{
            background-color: #ad7117;
        }
        .platinum{
            background-color: #828282;
        }
        .diamond{
            background-color: #1F4E79;
        }
        .master{
            background: #F2994A; /* fallback for old browsers */
            background: -webkit-radial-gradient(circle, #F2C94C, #F2994A); /* Chrome 10-25, Safari 5.1-6 */
            background: radial-gradient(circle, #F2C94C, #F2994A); 
        }
        .grandmaster{
            background: #6D6027; /* fallback for old browsers */
            background: -webkit-radial-gradient(circle, #6D6027, #D3CBB8);  /* Chrome 10-25, Safari 5.1-6 */
            background: radial-gradient(circle, #6D6027, #D3CBB8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            text-shadow: 5% 5% 5% #222;
        }

        .ranker{
            background-color: #1F4E79;
        }

            li{
                
                display: flex;
            }
            .positionIcon{
                filter: invert(100%) ;
            }
            .tierIcon{

            }
    }
    .profilePrivate, .profileError, .profileLode{
        text-align: center;
        background-color: #585858;
        padding: 0% 5%;
        height: 100%;
    }

}
/////////////////////////////////////////
// 투명스타일 모든포지션
/////////////////////////////////////////
.OverwatchRank_preset_clear{
    display: flex;
    // background-color: #222;
    border-radius: 10px !important;
    color: black;
    overflow: hidden;
    font-weight: bold;
    flex-direction: column;
    .userRank{
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .positionImg{
            object-fit: scale-down;
            width: auto;
            height: 50%; 
        }
        .tierImg{
            object-fit: scale-down;
            width: auto;
            height: 100%;  
        }
        .userName{
            width: 80%;
            margin: auto 10%;
        }
        .userRanklist{
            width: 33%;
            flex: 1;
            .clearImg{
                // width: 100%;
                display: flex;
                position: relative;

            }
            .rankinfo{
                display: flex;
                justify-content: space-between;
                width: 90%;
                height: 80%;
                margin: auto 5%;
                border-radius: 10px;
                span{
                    margin: 0px 5%;
                }
            }
            // background-color: blue;
        }
        // .bronze{
        //     background-color: #1F4E79;
        // }
        // .silver{
        //     background-color: #585858;
        // }
        // .gold{
        //     background-color: #ad7117;
        // }
        // .platinum{
        //     background-color: #828282;
        // }
        // .diamond{
        //     background-color: #1F4E79;
        // }
        // .master{
        //     background: #F2994A; /* fallback for old browsers */
        //     background: -webkit-radial-gradient(circle, #F2C94C, #F2994A); /* Chrome 10-25, Safari 5.1-6 */
        //     background: radial-gradient(circle, #F2C94C, #F2994A); 
        // }
        // .grandmaster{
        //     background: #6D6027; /* fallback for old browsers */
        //     background: -webkit-radial-gradient(circle, #6D6027, #D3CBB8);  /* Chrome 10-25, Safari 5.1-6 */
        //     background: radial-gradient(circle, #6D6027, #D3CBB8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //     text-shadow: 5% 5% 5% #222;
        // }

        .ranker{
            background-color: #1F4E79;
        }

            li{
                
                display: flex;
            }
            .positionIcon{
                filter: invert(100%) ;
            }
            .tierIcon{

            }
    }
    .profilePrivate, .profileError, .profileLode{
        text-align: center;
        // background-color: #585858;
        border: 3px solid#585858;
        border-radius : 10px;
        box-sizing: border-box;
        color: #585858;
        padding: 0% 5%;
        height: 100%;
    }
}

/////////////////////////////////////////
// 투명스타일 한포지션
/////////////////////////////////////////
.OverwatchRank_preset_clearShort{
    display: flex;
    // background-color: #222;
    border-radius: 10px !important;
    color: black;
    overflow: hidden;
    font-weight: bold;
    flex-direction: column;

    .userRank{
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background:none;

        .userName{
            width: 80%;
            margin: auto 10%;
        }
        .positionImg{
            object-fit: scale-down;
            width: auto;
            height: 50%; 
        }
        .userRanklist{
            width: 33%;
            flex: 1;

            .tierImg{
                object-fit: scale-down;
                width: auto;
                height: 100%;  
            }
            .clearImg{
                // width: 100%;
                display: flex;
                position: relative;

            }
            .rankinfo{
                display: flex;
                justify-content: space-between;
                width: 90%;
                height: 80%;
                margin: auto 5%;
                border-radius: 10px;
                span{
                    margin: 0px 5%;
                }
            }
            // background-color: blue;
        }
        // .bronze{
        //     background-color: #1F4E79;
        // }
        // .silver{
        //     background-color: #585858;
        // }
        // .gold{
        //     background-color: #ad7117;
        // }
        // .platinum{
        //     background-color: #828282;
        // }
        // .diamond{
        //     background-color: #1F4E79;
        // }
        // .master{
        //     background: #F2994A; /* fallback for old browsers */
        //     background: -webkit-radial-gradient(circle, #F2C94C, #F2994A); /* Chrome 10-25, Safari 5.1-6 */
        //     background: radial-gradient(circle, #F2C94C, #F2994A); 
        // }
        // .grandmaster{
        //     background: #6D6027; /* fallback for old browsers */
        //     background: -webkit-radial-gradient(circle, #6D6027, #D3CBB8);  /* Chrome 10-25, Safari 5.1-6 */
        //     background: radial-gradient(circle, #6D6027, #D3CBB8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //     text-shadow: 5% 5% 5% #222;
        // }

        .ranker{
            background-color: #1F4E79;
        }

            li{
                
                display: flex;
            }
            .positionIcon{
                filter: invert(100%) ;
            }
            .tierIcon{

            }
    }
    .profilePrivate, .profileError, .profileLode{
        text-align: center;
        // background-color: #585858;
        border: 3px solid#585858;
        border-radius : 10px;
        box-sizing: border-box;
        color: #585858;
        padding: 0% 5%;
        height: 100%;
    }

}