// 프리셋
p,h3,pre{
    margin: 0;
}

.Dday_preset_calendar{    
    border-radius: 30px;
    overflow: hidden;
    .head{
        height: 30%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .body{
        height: 70%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        .date{
            height: 30%;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }
        .day{
            height: 70%;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }      
    }    
}

.Dday_preset_miniWidget{    
    border-radius: 9999px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .head{
        width: 60%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .body{
        width: 40%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        .day{
            width: 100%;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }      
    }    
}
