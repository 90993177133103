// 프리셋

p,h3{
    margin: 0;
}
.OverwatchProfile_preset_normal{
    font-family: '나눔스퀘어';
    // border-radius: 5px;
    display: flex;

    .padding{
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 90%;
        // background-color: red;
        margin: auto;
        // overflow: hidden;
    }
    .userInfo{
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        .userTitle{
            width: auto;
        }
        .userName{
            overflow: hidden;
            margin-left: 20px;
            text-align: right;
        }
        .userRank{
            margin: 0;
            display: flex;
            justify-content: flex-end;
            list-style: none;
            .userRanklist{
                display: flex;
                margin: 0px 0.25%;
                .tierIcon{
                    // padding: auto;
                    // object-fit: cover;
                }
                .positionBox{
                    // width: 40px;
                    margin: auto 0.25%;
                    background-color: white;
                    filter: invert(180%);
                    display: flex;
                    .positionIcon {
                        margin: auto;
                    }
                }
            }
            .userRanklist{
    
            }
        }
    }
    hr{
        margin: 1.25% 0;
    }
    .dataField{
        display: flex;
        flex: 1;

        .profileData{
            display: flex;
            width: 100%;
            height: 100%;
            // background-color: red;
            ul{
                margin: 0;
                padding: 0;
            }
            ul.profileDatas{
                display: flex;
                flex-direction: column;
                width: 100%;
     
                padding: 0;
                margin: 0;
            }
            // 스타일
            .profileDataList{
                display: flex;
                justify-content: space-between;
                width: 100%;
                border-radius: 5px;
                overflow: hidden;
                color: white;
                // padding: 0% 5%;
                font-weight: bold;
                img{
                    object-fit: cover;
                
                }
                .profileDataType{
                    display: flex;  
                    .profileDatainfo{
                        // margin-left: 5px;
                        // width: 100%;
                        display: flex;
                        flex-direction: column;
                
                    }
                    img{
                        margin-right: 5px;
                    }
                    
                }
                .profileDataValue{
                    padding-right: 5%;
                    .MostList{
                        display: flex;
                        justify-content: flex-start;
                    }
                }
            }
            .profileDataType{

            }
            .profileD00ataValue{

            }
        }
        .profilePrivate{
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: #00000090;
        }
        .profileError{
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: #00000090;
        }
        .profileLode{
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: #00000090;
        }
    }
}
