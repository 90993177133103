

.BanPick{
    &.defaultBanPick{
        display: flex;
        color: white;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .banArea{
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 100px;
    
            .leftTeam{
                display: flex;
                .banElement{
                    box-sizing: border-box;
                    background-color: gray;
                    border-right: 1px solid black;

                }
            }
            .rightTeam{
                display: flex;
                .banElement{
                    box-sizing: border-box;
                    background-color: gray;
                    border-left: 1px solid black;
        
                }
                 
            }
            .banElement{
                flex : 1;
                position: relative;
                .banImage{
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    top : 0px;
                    display: flex;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    }
                }
                .banIcon{
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
                .TargetPoint{
                    position: absolute;
                    z-index: 3;
                    background: #ff000077;
                    animation: fadeInOut 3s ease-in-out infinite;
                    width: 100%;
                    height: 100%;
                    top :0;
                }
        }
        .timerArea{
            display: flex;
            width: 100%;

            background-color: white;
        }
        .pickArea{
            background-color: black;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .leftTeam{
                width: 40%;
                display: flex;
                height: 100%;
            }
            .centerInfo{
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                font-family: '프리텐다드Black';
                .mainInfo{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    .TeamName{
                        font-size: 30px;
                    }
                    .TeamImage{
                        display: flex;
                        width: 60%;
                        aspect-ratio: 1 / 1;
                        border-radius: 10px;
                        margin-bottom: 10px;
                    }
                    .leftTeamInfo{
                        width: calc((100% - 20px) / 2);
                        font-size: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
             
                    }
                    .centerTeamInfo{
                        margin : 10px;
                        font-size: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    .rightTeamInfo{
                        width: calc((100% - 20px) / 2);
                        font-size: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
    
                }
            
            }


            .rightTeam{
                width: 40%;
                display: flex;
                height: 100%;
            }
            .pickLine{
                display: flex;
                width: 2px;
                height: 100%;
                background: white;  /* fallback for old browsers */
                background: -webkit-linear-gradient(to top, black, #ffffff80,#ffffff80,#ffffff80, black);  /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to top, black, #ffffff80,#ffffff80,#ffffff80, black); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            }
            .pickElement{
                flex : 1;
       
                // background: #0f0c29;  /* fallback for old browsers */
                // background: -webkit-linear-gradient(to right, #0f0c29, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
                // background: linear-gradient(to right, #0f0c29, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                position: relative;
                .pickImage{
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    top : 0px;
                    display: flex;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                }
                .pickIcon{
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
                .pickInfo{
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    top : 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    .pickTarget{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .pickUser{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 50px;
                            text-align: center;
                            font-size: 30px;
                            background-color: #00000082;
                            text-shadow: 1px 1px solid black;
                            margin: 0;
                            padding: 0;
                            color: white;
                    }

                }
                .TargetPoint{
                    position: absolute;
                    z-index: 3;
                    background: linear-gradient(to bottom, transparent, orange);
                    animation: fadeInOut 3s ease-in-out infinite;
                    width: 100%;
                    height: 100%;
                    top :0;
                  
                }
                @keyframes fadeInOut {
                    0%, 100% {
                      opacity: 0;
                    }
                    50% {
                      opacity: 1;
                    }
                  }
                  
                
            }
        }

    }
}

