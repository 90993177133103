// 프리셋
p,h3,pre{
    margin: 0;
}

.BroadcastAlert_preset_normal{    
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden;
    background-color: blue;
    .time{
        background-color: red;
        border-radius: 10px;
    }
    .name{
        flex: 1 ;
    }
}
