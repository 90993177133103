// 프리셋
p,h3,pre{
    margin: 0;
}


.Notice_preset_Postit.horizontal {
    display: flex;
    // background: linear-gradient(to right, #f8f861,#f8f861,#00000000,#00000000 );
    .postSticker{
        width: 20px;
        height: 100%;
        // background-color: #f8f861;
        margin: 0;

    }
    .postTextPadding{
        width: 100%;
        height: 20px;
    }
    .postMain{
        // display: flex;
        flex: 1;
        word-break:break-all;
        white-space: pre-line;
        box-sizing:border-box;
        padding: 10px;
    }
    .postContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        word-break: break-all;
        // background: linear-gradient(
        //     135deg
        //     , #ffff88 81%, #ffff88 82%, #ffff88 82%, #ffffc6 100%);
        border-bottom-right-radius: 160px 20px;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: 35px;
            width: 100%;
            height: 100%;

            box-shadow: 2px 35px 5px rgba($color: #000000, $alpha: 0.4);
        }
    }
}


.Notice_preset_Postit.vertical {
    display: flex;
    flex-direction: column;
    // background: linear-gradient(to right, #f8f861,#f8f861,#00000000,#00000000 );
    .postSticker{
        width: 100%;
        height: 20px;
        // background-color: #f8f861;
        margin: 0;
    }
    .postTextPadding{
        width: 20px;
        height: 100%;
    }
    .postMain{
        // display: flex;
        flex: 1;
        word-break:break-all;

        white-space: pre-line;
        box-sizing:border-box;
        padding: 10px;
    }
    .postContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        word-break: break-all;
        // background: linear-gradient(
        //     135deg
        //     , #ffff88 81%, #ffff88 82%, #ffff88 82%, #ffffc6 100%);
        border-bottom-right-radius: 40px 40px;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: 80px;
            width: 100%;
            height: 100%;

            box-shadow: 2px 80px 5px rgba($color: #000000, $alpha: 0.4);
        }
    }
}


.Notice_preset_Modern{
    border-radius: 15px;
    padding: 10px;
    box-sizing: border-box;
    .ModernMain{
        flex: 1;
        word-break:break-all;
        white-space: pre-line;
    }
}
.Notice_preset_yellowPostit_Horizontal{
    display: flex;
    background: linear-gradient(to right, #f8f861,#f8f861,#00000000,#00000000 );
    .postSticker{
        width: 20px;
        height: 100%;
        background-color: #f8f861;
        margin: 0;
    }
    .postTextPadding{
        width: 100%;
        height: 20px;
    }
    .postMain{
        // display: flex;
        flex: 1;
        word-break:break-all;
        white-space: pre-line;
    }
    .postContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        word-break: break-all;
        background: linear-gradient(
            135deg
            , #ffff88 81%, #ffff88 82%, #ffff88 82%, #ffffc6 100%);
        border-bottom-right-radius: 160px 20px;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: 35px;
            width: 100%;
            height: 100%;

            box-shadow: 2px 35px 5px rgba($color: #000000, $alpha: 0.4);
        }
    }
}
.Notice_preset_yellowPostit_vertical{
    display: flex;
    flex-direction: column;
    background: linear-gradient(to right, #f8f861,#f8f861,#00000000,#00000000 );
    .postSticker{
        width: 100%;
        height: 20px;
        background-color: #f8f861;
        margin: 0;
    }
    .postTextPadding{
        width: 20px;
        height: 100%;
    }
    .postMain{
        // display: flex;
        flex: 1;
        word-break:break-all;

        white-space: pre-line;
    }
    .postContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        word-break: break-all;
        background: linear-gradient(
            135deg
            , #ffff88 81%, #ffff88 82%, #ffff88 82%, #ffffc6 100%);
        border-bottom-right-radius: 40px 40px;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: 80px;
            width: 100%;
            height: 100%;

            box-shadow: 2px 80px 5px rgba($color: #000000, $alpha: 0.4);
        }
    }

}

.Notice_preset_pinkPostit_Horizontal{
    display: flex;
    background: linear-gradient(to right, #FDE1F0,#FDE1F0,#00000000,#00000000 );
    .postSticker{
        width: 20px;
        height: 100%;
        background-color: #FDE1F0;
        margin: 0;
    }
    .postTextPadding{
        width: 100%;
        height: 20px;
    }
    .postMain{
        // display: flex;
        flex: 1;
        word-break:break-all;
        white-space: pre-line;
    }
    .postContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        word-break: break-all;
        background: linear-gradient(
            135deg
            , #FCD0E2 81%, #FCD0E2 82%, #FCD0E2 82%, #FDE1F0 100%);
        border-bottom-right-radius: 160px 20px;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: 35px;
            width: 100%;
            height: 100%;

            box-shadow: 2px 35px 5px rgba($color: #000000, $alpha: 0.4);
        }
    }

}

.Notice_preset_pinkPostit_vertical{
    display: flex;
    flex-direction: column;
    background: linear-gradient(to right,  #FDE1F0,#FDE1F0,#00000000,#00000000 );
    .postSticker{
        width: 100%;
        height: 20px;
        background-color: #FDE1F0;
        margin: 0;
    }
    .postTextPadding{
        width: 20px;
        height: 100%;
    }
    .postMain{
        // display: flex;
        flex: 1;
        word-break:break-all;
        white-space: pre-line;
    }
    .postContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        word-break: break-all;
        background: linear-gradient(
            135deg
            , #FCD0E2 81%, #FCD0E2 82%, #FCD0E2 82%, #FDE1F0 100%);
        border-bottom-right-radius: 40px 40px;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: 80px;
            width: 100%;
            height: 100%;

            box-shadow: 2px 80px 5px rgba($color: #000000, $alpha: 0.4);
        }
    }

}

// 0 2px 4px rgba(0, 0, 0, 0.1),
// 0 6px 8px rgba(0, 0, 0, 0.1),
// 0 12px 16px rgba(73, 25, 25, 0.1),
// 0 18px 24px rgba(0, 0, 0, 0.1)

.Notice_preset_Apps{
    .notepad{
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: hidden;
        border-radius: 5px;
    //     box-shadow: 
    //   0 2px 4px rgba(0, 0, 0, 0.1),
    //   0 6px 8px rgba(0, 0, 0, 0.1),
    //   0 12px 16px rgba(73, 25, 25, 0.1),
    //   0 18px 24px rgba(0, 0, 0, 0.1);
        .notepadNav{
            background-color: #e0e0e0;
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-left: 10px;
                font-size: 20px;
     
                display: flex;
            }
            .notepadButtonArea{
                display: flex;
                margin-right: 10px;
                .notepadButton{
                    height: 20px;
                    width: 20px;
                    border-radius: 20px;
                    margin: 5px;
                }
            }
        }  
        .notepadMain{
            box-sizing: border-box;
            padding: 10px;
            word-break: break-all;  /* 단어를 잘라서라도 줄바꿈 */
            overflow-wrap: break-word; /* 텍스트 줄바꿈을 유연하게 처리 */
            white-space: pre-line;
        }
        
    }

}

.Notice_preset_Receipt{
    
    .receipt {
        height: calc( 100% - 80px);
        margin : 20px 0px;
        display: flex;
        flex-direction: column;
        background: white;
        padding: 20px;
        border: 1px solid #ddd;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        position: relative;
    }
    .receipt::before, .receipt::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 20px;
        background: white;

    }
    .receipt::before {
        top: -20px;
        clip-path: polygon(0% 100%, 5% 0%, 10% 100%, 15% 0%, 20% 100%, 25% 0%, 30% 100%, 35% 0%, 40% 100%, 45% 0%, 50% 100%, 55% 0%, 60% 100%, 65% 0%, 70% 100%, 75% 0%, 80% 100%, 85% 0%, 90% 100%, 95% 0%, 100% 100%);
    }
    .receipt::after {
        bottom: -20px;
        clip-path: polygon(0% 0%, 5% 100%, 10% 0%, 15% 100%, 20% 0%, 25% 100%, 30% 0%, 35% 100%, 40% 0%, 45% 100%, 50% 0%, 55% 100%, 60% 0%, 65% 100%, 70% 0%, 75% 100%, 80% 0%, 85% 100%, 90% 0%, 95% 100%, 100% 0%);
    }
    .receipt h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 30px;
    }
    .receipt .line {
        border-bottom: 1px dashed #ddd;
        margin: 10px 0;
    }
    .receipt .item {
        padding: 10px;
        white-space: pre-wrap; /* 줄바꿈을 허용 */
        word-break: break-all;  /* 단어를 잘라서라도 줄바꿈 */
        overflow-wrap: break-word; /* 텍스트 줄바꿈을 유연하게 처리 */
    }
    .receipt .total {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        margin-top: 15px;
    }
    .receipt .thanks {
        text-align: center;
        margin-top: 20px;
        font-style: italic;
    }
}