#FrontHeader{
    background-color: #222;
    height: 50px;
    // background-color: orange;
    width: 100%;
    overflow: hidden;
    #FrontHeaderArea{
        font-family: '프리텐다드ExtraBold';
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: auto;
        #FrontHeaderLogo{
            display: flex;
            color: #fff;
            text-decoration: none;
            margin-right: 10px;
            .mainLogo{
                height: 40px;
                margin: 5px 5px 5px 0px;
                
            }
            h1{
                margin: 0px;
                font-size: 30px;
                line-height: 50px;
            }
 
        }
        nav{
    
            display: flex;
            .btn{
                background-color: orange;
                color: white;
                height: 35px;
                margin: 7.5px;
                line-height: 35px;
                font-size: 17px;
                width: 150px;
                text-align: center;
                border-radius: 2.5px;
                text-decoration: none;
            }
            .able:hover{
                background-color: rgb(223, 152, 20);
            }
            .disable{
                background-color: #666;
                color: #888;
            }
            h3{
                margin: 0px 20px;
                font-size: 17px;
                line-height: 50px;

            }
            .link{
                text-decoration: none;
                color: white;
            }
        }
    }
    p{
        margin: 0;
    }


}
@media screen and (max-width: 600px) {
    #FrontHeader{
        #FrontHeaderArea{
            display: flex;
            justify-content: space-between;
            max-width: 1200px;
            margin: auto;
            #FrontHeaderLogo{
                display: flex;
                color: #fff;
                text-decoration: none;
                margin-right: 10px;
                .mainLogo{
                    height: 40px;
                    margin: 5px 5px 5px 0px;
                    
                }
                h1{
                    margin: 0px;
                    font-size: 30px;
                    line-height: 50px;
                    font-weight: 700;
                }
    
            }
            nav{   
                
                margin: auto;
                display: flex;
                justify-content: center;
                .btn{
                    display: none;
                }
                .login{
                    display: inline-block;;
                    text-align: center;
                }
                h3{
                    display: none;
                }
            }
        }
        p{
            margin: 0;
        }


    }
}
.relative{
    position: relative;
}

#FrontPage{
    #FrontHeader{
        position:fixed;
        z-index: 999;
    }
    #FrontSideMenu{
        top: 50%;
        transform: translateY(-50%);
        position:fixed;
        z-index: 999;
        font-family: "프리텐다드SemiBold", sans-serif;
        li{
            color: #444;
            padding: 10px;
            text-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
            transition: all 0.3s;
            cursor:pointer;
        }
        li.select{
            color: #000;
            font-size: 20px;
        }
    }
    #FrontHeaderDumi{
        height: 50px;
        width: 100%;
    }
    #FrontPageContainer{
        width: 100%;
        height: calc(100vh - 50px);
        overflow: hidden;
        section{
            width: 100%;
            // background: #444;
            height: calc(100vh - 50px);
            .sectionContainer{
                max-width: 1200px;
                margin: auto;
                height: 100%;
                display: flex;
                position: relative;
            }
        }
        #OverlapIntroduce{
            background-color: #F3CB34;
            .sectionContainer{
                .startOverlap{
                    top:50%;
                    left: 0%;
                    position: absolute;
                    background-color: white;
                    backdrop-filter: saturate(180%) blur(20px);
                    width: 470px;
                    height: 60px;
                    font-family: "잘난체", sans-serif;
                    border-radius: 5px;
                    display: flex;
                    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    p{
                        width: 400px;
                        margin: 10px;
                        font-size: 40px;
                        line-height: 50px;
                        text-align: right;

                    }
                    .Logo{
                        height: 50px;
                        margin: 5px;
                    }
                }
                .startOverlap:hover{
                    // background-color: orange;
                }
                .textFiled{
                    top:55%;
                    left: 0%;
                    position: absolute;
                    z-index: 1;
                    color: white;
                    text-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    .start{
                        background-color: orange;
                        height: 50px;
                        width: 250px;
                        line-height: 50px;
                        font-size: 25px;
                        padding: 10px;
                        margin-bottom: 20px;
                        border-radius: 5px;
                        font-family: "프리텐다드ExtraBold", sans-serif;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.1), 0 12px 16px rgba(73, 25, 25, 0.1), 0 18px 24px rgba(0, 0, 0, 0.1);
                        transition: transform 0.5s;
                        text-decoration-line: none;
                        color: white;
                        margin-left: auto;
                    }
                    .startLink{
                        text-decoration: none;
                    }
                    .start:hover{
           
                        transform: scale(1.05);
                    }
                    h3{
                        font-size: 75px;
                        line-height: 85px;
                        font-family: "잘난체", sans-serif;
                    }
                    hr{
                        margin: 10px 0px;
                        border: 1px solid white;
                    }
                    p{
                        font-size: 23px;
                        line-height: 35px;
                        font-family: "프리텐다드ExtraBold", sans-serif;
                    }
               
                }
                .logoFiled{
                    top:65%;
                    left: 0%;
                }
                .mainFiled{
                    top:-50px;
                    right: 0%;
                    position: absolute;
                    img{
                        width: 700px;
                        // filter: drop-shadow(rgba(0, 0, 0, 0.1) -5px -5px 5px) drop-shadow(rgba(0, 0, 0, 0.05) -35px -35px 10px) ;
                    }
                }
                .circleFiled_1{
                    
                    top:-250px;
                    left: -150px;
                    position: absolute;
                    width: 400px;
                    height: 400px;
                    background-color: white;
                    border-radius: 999px;
                }
                .circleFiled_2{
                    bottom: -300px;
                    right: -150px;
                    position: absolute;
                    width: 600px;
                    height: 600px;
                    background-color: white;
                    border-radius: 999px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    .start{
                        margin-top: 80px;
                        transform: translateX(60px);
                        width: 200px;
                        height: 50px;
                        padding: 10px;
                        border-radius: 10px;
                        background-color: orange;
                        font-family: "프리텐다드ExtraBold", sans-serif;
                        color: white;
                        line-height: 50px;
                        font-size: 25px;

                    }
           
                    .scrollGide{
                        text-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                        margin-top: 190px;
                        font-family: "잘난체", sans-serif;
                        font-size: 30px;
                        animation:scrollGideMove 1s ease-in Infinite Alternate;
                    }
                    @keyframes scrollGideMove {
                        0% {
                            margin-top: 190px;
                        }
                        100% {
                            margin-top: 210px;
                        }
                      }

                }
            }
        }
        #WidgetIntroduce{
            .sectionContainer{
    
                .textFiled{
                    top:60%;
                    left: 0%;
                    position: absolute;
                    z-index: 1;
                    color: black;
                    text-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    h3{
                        font-size: 60px;
                        line-height: 70px;
                        font-family: "잘난체", sans-serif;
                    }
                    hr{
                        margin: 10px 0px;
                        border: 1px solid black;
                    }
                    p{
                        font-size: 23px;
                        line-height: 35px;
                        font-family: "프리텐다드ExtraBold", sans-serif;
                    }
                }
                .logoFiled{
                    top:65%;
                    left: 0%;
                }
                .mainFiled{
                    top: 2.5%;
                    right: -300px;
                    position: absolute;
                    img{
                        width: 1300px;
                    }
                }
            }
        }
        #OverlapHowtoUse{
            background-color: #F3CB34;
            overflow: hidden;
            .sectionContainer{
 
                .textFiled{
                    top:60%;
                    left: 0%;
                    position: absolute;
                    z-index: 1;
                    color: white;
                    text-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    h3{
                        font-size: 85px;
                        line-height: 95px;
                        font-family: "잘난체", sans-serif;
                        small{
                            font-size: 30px;
                            line-height: 40px;
                        }
                    }
                    hr{
                        margin: 10px 0px;
                        border: 1px solid white;
                    }
                    p{
                        font-size: 23px;
                        line-height: 35px;
                        font-family: "프리텐다드ExtraBold", sans-serif;
                    }
                }
                .logoFiled{
                    top:65%;
                    left: 0%;
                }
                .imgFiled{
                    top:-5px;
                    right:  calc( calc(100vw - 1200px) / 2 * -1);
                    position: absolute;
                    transform: rotate(180deg);
                
                    img{
                        filter: drop-shadow(rgba(0, 0, 0, 0.1) -5px -5px 5px) drop-shadow(rgba(0, 0, 0, 0.05) -35px -35px 10px) ;
                        height: calc(100vh - 45px);
                    }
                }
               
            }
        }
        #AppIntroduce{
            .sectionContainer{
    
                .textFiled{
                    top:60%;
                    left: 0%;
                    position: absolute;
                    z-index: 1;
                    color: black;
                    h3{
                        font-size: 60px;
                        line-height: 70px;
                        font-family: "잘난체", sans-serif;
                    }
                    hr{
                        margin: 10px 0px;
                        border: 1px solid black;
                    }
                    p{
                        font-size: 23px;
                        line-height: 35px;
                        font-family: "프리텐다드ExtraBold", sans-serif;
                    }
                }
                .logoFiled{
                    top:65%;
                    left: 0%;
                }
                .mainFiled{
                    top:-50px;
                    right: 0%;
                    position: absolute;
                    img{
                        width: 700px;
                    }
                }
                .circleFiled_1{
                    
                    top:-250px;
                    left: -150px;
                    position: absolute;
                    width: 400px;
                    height: 400px;
                    background-color: white;
                    border-radius: 999px;
                }
                .circleFiled_2{
                    bottom: -300px;
                    right: -150px;
                    position: absolute;
                    width: 600px;
                    height: 600px;
                    background-color: white;
                    border-radius: 999px;
                }
            }
        }
        #OverlapHelp{
            background-color: #f8f8f8;
            .sectionContainer{
    
                .textFiled{
                    top:60%;
                    left: 0%;
                    position: absolute;
                    z-index: 1;
                    color: black;
                    text-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    h3{
                        font-size: 60px;
                        line-height: 70px;
                        font-family: "잘난체", sans-serif;
                    }
                    hr{
                        margin: 10px 0px;
                        border: 1px solid black;
                    }
                    p{
                        font-size: 23px;
                        line-height: 35px;
                        font-family: "프리텐다드ExtraBold", sans-serif;
                    }
                }
                .logoFiled{
                    top:65%;
                    left: 0%;
                }
                .mainFiled{
                    top:-50px;
                    right: 0%;
                    position: absolute;
                    img{
                        width: 700px;
                    }
                }
                .menuFiled{
                    top:10%;
                    right: 0%;
                    position: absolute;
                    div{
                        overflow: hidden;
                        display: flex;
                        justify-content: space-between;
                        width: 500px;
                        height: 100px;
                        margin: 20px;
                        background-color: white;
                        border-radius: 10px;
                        box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                        transition: transform 0.3s;
                        &:hover{
                            transform: scale(1.1);
                        }
                        .logobg{
                            background-color: orange;
                            width: 80px;
                            height: 80px;
                            padding: 10px;
                
                        }
                        .talk{
                            background-color:#F6E400;
                        }
                        img{
                            width: 100px;
                            height: 100px;
                        }
                        h3{
                            line-height: 100px;
                            font-size: 35px;
                            margin: 0px 20px;
                            font-family: "잘난체", sans-serif;
                        }
                    }
                }
            }
        }
    }
}


.testtstst {

    align-items: center;
    line-height: 15px;
}