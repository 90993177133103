
////////////////////////////////////////////////////////
// 사이드바 에딧 변수 Scss
////////////////////////////////////////////////////////
$EditModuleButton : #222;
$EditModuleSelect : orange;

////////////////////////////////////////////////////////
// 사이드바 에딧 필드
////////////////////////////////////////////////////////
.EditModuleField{
    width: 100%;
    display: flex;
    margin: 10px 0;
    flex-direction: column;
    border : solid 1px #fff;
    box-sizing: border-box;
    legend{
        font-size: 14px;
    }
}



////////////////////////////////////////////////////////
// 뉴모피즘 기반의 디자인 
////////////////////////////////////////////////////////

$EditGuideLine: 1px solid #888;

$Editradius: 5px;

$EditSideMargin: 5px;

////////////////////////////////////////////////////////
// 전역설정
///////////////////////////////////////////////////////


/* 추가적인 호환성을 위해 모든 스크롤 가능한 요소에 overflow 설정 */
html, body {
    overflow: auto;
}


.Neumorphism {
    button{
        margin: 0;
        padding: 0;
        background-color: #ffffff00;
        // border-radius: 5px;
        border: 0;
        color: #fff;
    }
    

    .EditTitle{
        font-family: '프리텐다드Medium';
        width: 100%;
        background-color: #252525;
        padding: 5px 15px;
        border-radius: $Editradius;
        box-sizing: border-box;
        margin: 15px 0px 10px 0;
        display: flex;
        justify-content: space-between;
        .titleContainer{
            display: flex;
            .editorIcon{
                height: 25px;
                width: 25px;
                margin: 2.5px 5px 2.5px 0px;
                border: none;   
                .iconfont{
                    font-display: block;
                    font-size: 20px;
                    line-height: 25px;
                }
            }
            .editorTitle{
                padding: 5px 0px;
                height: 20px;
                margin: 0px;
            }
        }
        .optionContainer{
            display: flex;
            flex : 1;
            justify-content: flex-end;
            align-items: center;
            .EditerButtons{
                padding: 5px 0px;
                button{
                    width: 20px;
                    height: 20px;
                    border-radius: 5px;
                }
                
                .editorToggleButton{
                    margin-left: 10px;
                    display: flex;
                    justify-content: center;
                    .editorToggle{
                        transition: transform 0.1s;
                        display: flex;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                    }
                    .editorOpen{
                        transform: rotate(180deg);
                    }
                    .editorClose{
                        transform: rotate(0deg);
                    }
                }
    
            }
        }
   
    }
    .editContainer{
        width: 100%;
        transition: transform 1s;
        transform-origin: top;
    }
    .containerOpen{
    }
    .containerClose{
        display: none;
    }
    article.EditPart{
        font-family: "프리텐다드Medium", sans-serif;
        border-radius: $Editradius;
        background: #444444;
        box-shadow:  3.5px 3.5px 10px #252525,
                    -3.5px -3.5px 10px #636363;
        margin: 10px 0px;
        width: calc(100% - 30px) ;
        padding: 5px 15px;
    }
    article.EditPart .fromText{
        margin: 10px 0px;
    }
    .optionButton {
        outline: none;
        background: #444444;
        // margin: 10px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2.5px;
        .ButtonImage{
            width: 80%  !important;  
            height: 80%  !important; 
        }
    }
    
    .buttonSelect{
        background: #262626 !important; 
        box-shadow:  3.5px 3.5px 10px #252525,
        -3.5px -3.5px 10px #636363;
     
    }
    .EditModuleTitle{
        display: flex;
    }
    h5{
        display: flex;
        align-items: center;
        margin: 5px 0px;
        font-size: 16px;
    }
    .EditModule{
        display: flex;
        flex-direction: row;
    }
    .SummaryTitle{
        font-size: 14px;
        font-family: '프리텐다드Medium';
        margin: 5px 0px;
    }
    .EditSummaryData{
        display: flex;
        flex-wrap: wrap;
        margin: 5px 0px;
        font-size: 14px;
        dt{
            font-family: '프리텐다드Medium';
            width: 100px;
            text-align: left;
        }
        dd {
            margin: 0px 0px 0px 10px;
            font-family: '프리텐다드Regular';
            flex: 1;
        }
    }

    textarea{
        display: flex;
        resize: vertical;
        box-sizing: border-box;
        font-family: '프리텐다드Regular';
        width: 100%;
        font-size: 11px;
        min-height: 26px;
        line-height: 13px;
        background-color: #222;
        border : $EditGuideLine;
        border-radius: 5px;
        color: #c5c8ce;
        &::placeholder{
            color: #aaa;
        }
        &::-webkit-scrollbar {
            width: 8px;
            height: 2px;
            // background: #ffffff;
          }
        &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #555;
        background-clip: padding-box;
        border: 1px solid transparent;
        &:hover {
            background-color: orangered;
        }
        }
        &::-webkit-scrollbar-track {

        }
        
    }
    .radioSelect{
        width: 100%;
        height: 30px;
        margin: 5px 0 0 0;
        display: flex;
    
        .select + label{
            background-color: orange;
        }
        input[type="radio"]{
            display: none;
            margin: 0;
        }
        label{
            background-color: #666;
            margin-right: 5px;
            padding: 5px;
            font-weight: bold;
            overflow: hidden;
        }
    
    }
    .notice{
        font-family: '프리텐다드Regular';
        background-color: #333;
        padding: 5px;
        border-radius: 5px;
        text-align: center;
        margin: 5px 0;
    }
    .notice{
        font-family: '프리텐다드Regular';
        background-color: #333;
        padding: 5px;
        border-radius: 5px;
        text-align: center;
        margin: 5px 0;
    }
    .SubHead{
        display: flex;
        margin: 5px 0px;
        .editorIcon{
            display: flex;
            height: 25px;
            width: 25px;
            margin: 2.5px 5px 2.5px 0px;
            border: none;   
        }
        .SubHeadText{
            display: flex;
            margin: 0px 0px;
            font-size: 16px;
            padding: 5px 0px;
        }
    }
}


////////////////////////////////////////////////////////
// 사이드바 에딧 모듈
////////////////////////////////////////////////////////
.ModuleContainer{
    display: flex;
    justify-content: space-between;
}
.ValueTypeContainer{
    width: 45px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    .ValueTypeButton{
        margin-left: 5px;
        width: 35px;
        padding: 0px 5px;
        height: 26px;
        display: flex;
        justify-content: space-between;
        .ValueTypeInfo{
            width: 20px;
            font-size: 15px;
            text-align: center;
            line-height: 26px;
            flex: 1;
        }
        .ValueTypeChange{
            width: 15px;
            font-size: 7px;
            text-align: center;
            line-height: 26px;
        }
    }

}
.EditModule{
    flex: 1;
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 20px;
    padding: 5px 0;
    // border-bottom: solid 1px white;
    .editNav{

    }
    p{
        font-size: 16px;
        line-height: 26px;
        margin: 0;
        max-width: 100px;
        padding: 0;
        text-align: left;
    }
    input[type="text"]{
        background-color: #222;
        color: white;
        border: 0;
        border : 1px solid #727272;
        overflow: scroll;
    }
    input[type="color"]{
        height: 20px;
        width: 20px;
        border: 0;
        padding: 0;
        border-radius: 9999px;
        background-color: #ffffff00;
    }
    input[type="number"]{
        font-size: 20px;
        height: 20px;
        width: 100px;
        padding: 2px 6px 2px 11px;
        padding: 2px 0px ;
        text-align: right;  
    }
    input[type="range"]{
        display: flex;
        flex: 1;
        width: 100px;
        padding: 0;
        margin: auto;
      }
    
    input{
        background-color: #222;
        padding: 2px 11px;
        border-radius: 5px;
        color: #000;    
        color: #fff;
        border : none;
        // box-shadow: 0px 0px 1px rgba(0,0,0,0.8) inset,0px 0px 3px rgba(200,200,200,0.5); 
    }
    .switch{
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        
        input {
            width: 0;
            height: 0;
            opacity: 0;

          }
        .slider {

            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $EditModuleButton;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;
            background-color: #333;
        }
        .slider:before {
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
            
          }
          input:checked + .slider {
            background-color: #62C796;
          }
          
          input:focus + .slider {
            box-shadow: 0 0 1px black;
          }
          
          input:checked + .slider:before {
            -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
            transform: translateX(20px);
          }
    }



    button{
        background-color: #ffffff00;
        // border-radius: 5px;
        border: 0;
        color: #fff;
    }
    .dropdownSelet{
        background-color: #222;
        color: white;
        border: 0;
        flex: 1;
        margin-left: 10px;
        border : 1px solid #727272;
        text-align: right;
        overflow: hidden;
        font-family: "프리텐다드SemiBold", sans-serif;
    }
    .dropdown{
        position: absolute;
        background-color: #333;
        text-align: right;
        margin: 35px 0 0 0;
        max-height: 300px;
        padding: 0;
        border-radius: 5px;
        width: 100%;
        overflow: auto;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
        z-index: 999;
        li{
            margin: 0px 2px 0px 10px;
            padding: 5px 10px;
            list-style: none;
            border-bottom: 1px solid #666;
        }
        li:hover{
            background-color: #666;
        }
        &::-webkit-scrollbar {
            width: 8px;
            height: 2px;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
            // background: #ffffff;
          }
        &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #555;
        background-clip: padding-box;
        border: 1px solid transparent;
        
        &:hover {
            background-color: orangered;
        }
        }
        &::-webkit-scrollbar-track {

        }

    }

}

////////////////////////////////////////////////////////
// 프리셋에딧 전역설정
////////////////////////////////////////////////////////
.selectPreset{
    list-style: none;
    font-weight: bold;
    background-color: #222;
}
.presetList{
    list-style: none;
}
////////////////////////////////////////////////////////
// SentenceSubmit
////////////////////////////////////////////////////////
.SentenceSubmit{
    .SentenceField{
        display: flex;
        justify-content: space-between;
        input[type=text]{
            background-color: #222;
            color: #fff;
            border: 1px solid #d9d9d9;
            display: flex;
            flex: 1;
        }
        input[type=text]::placeholder{
            color: white;
            font-weight: bold;
        }
        input[type=submit]{
            background-color: orange;
            color: white;
            border: 0;
            display: inline-block;
            cursor: pointer;
            font-weight: bold;
            border-radius: 5px;
            padding: 5px 20px;
            margin-left: 5px;
        }
        input[type=submit]:hover{
            background-color: orangered;
        }
    }


}

////////////////////////////////////////////////////////
// 스폐셜 에디터
////////////////////////////////////////////////////////

.dropDownEditer{
    position: absolute;
    background-color: #333;
    margin: 30px 0 0 0;
    padding: 0;
    border-radius: 5px;
    width: 100%;
    overflow: auto;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
    z-index: 999;
}

////////////////////////////////////////////////////////
// 텍스트 모듈 전용
////////////////////////////////////////////////////////
.TextModule{
    flex-direction: column;
    p{
        font-size: 16px;
        margin: 0;
        padding: 0;
        text-align: left;
    }
    textarea{
        resize: vertical;
        width: 100%;
        font-size: 13px;
        line-height: 13px;
        background-color: #222;
        border : 1px solid #d9d9d9;
        color: white;
        &::placeholder{
            color: #aaa;
        }
        &::-webkit-scrollbar {
            width: 8px;
            height: 2px;
            // background: #ffffff;
          }
        &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #555;
        background-clip: padding-box;
        border: 1px solid transparent;
        &:hover {
            background-color: orangered;
        }
        }
        &::-webkit-scrollbar-track {

        }
        
    }

}
.StringModule{
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // p{
    //     font-size: 16px;
    //     margin: 0;
    //     width: 100px;
    //     line-height: 26px;
    //     padding: 0;
    //     text-align: left;
    // }
    // input {
    //     background-color: #222;
    //     padding: 2px 11px;
    //     border-radius: 2px;
    //     color: #000;
    //     color: #fff;
    //     border: 1px solid #727272;
    // }
}


.EightyDirectionsModule{
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px white;
    padding: 5px 0px;
    div{
        width: 50%;

        input{
            width: 30%;
            height: 30px;
            line-height: 30px;
            background-color: #666;
            border: 0;
            padding: 0;
            margin: 1px;
        }
    }
    p{
        font-size: 20px;
        margin: 0;
        padding: 0;
        text-align: left;
    }

}

.ColorModule{
    &.V2{
        display: flex;
        .infoSide{


        }
        .selectSide{
            display: flex;
            height: 26px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .colorView{
                display: flex;
                width: 20px;
                height: 20px;
                margin-right: 5px;
                border-radius: 50px;
                background-color: red;
                // background: #262626 !important;
                box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
            }
        }
        
    }
}

.ColorPicker{
    display: flex;
    flex-direction: column;
    min-height : 200px;
    margin: 10px;
    // background-color: red;
    .colorPickerHeader{
        width: 100%;
        min-height: 40px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .colorPickerTypeInfo{
            // width: 26px;
            height: 26px;
            line-height: 26px;
            margin: 2px;
        }
        // background-color: #000;
        .colorPickerType{
            margin: 2px;
            display: flex;
            flex-direction: row;

            .typeButton{
                width: 26px;
                height: 26px;
                margin-right: 5px;
                outline: none;
                background: #444444;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 2.5px;
            }
        }
    }

    .colorPickerSelect{
        width: 100%;
        box-sizing: border-box;
        height: 200px;
        // background-color: red;
        .colorPickerTypeZone{

        }
        .colorPickerPaletteZone{
            width: 100%;
            height: 100px;
            margin: 10px 0px 10px 0px;
            // background-color: red;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            .colorPalette{
                width: calc(100% - 20px);
                height: 100px;
                background-color: #000;
                border-radius: 5px;
                // margin: 5px;
                box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
            }
            .rgbPalette{
                width: 10px;
                height: 100px;
                border-radius: 5px;
                background: linear-gradient(to top,hsl(0,100%,50%),hsl(60,100%,50%),hsl(120,100%,50%),hsl(180,100%,50%),hsl(240,100%,50%),hsl(300,100%,50%),hsl(360,100%,50%));
                box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
            }
            .rgbContorl{
                
            }
        }
        // background-color: #000;
        .colorPickerHexZone{
            width: 100%;
            .colorPickerHex{
                font-family: "프리텐다드SemiBold", sans-serif;
                background-color: #222;
                outline: none;
                border: none;
                width: 100px;
                height: 20px;
                color: white;
                font-size: 20px;
                border-radius: 999px;
            }
        }
      
    }
}
////////////////////////////////////////////////////////
// 비디오 모듈 전용
////////////////////////////////////////////////////////
.VideoEdit {

    #videoSelectyYoutube:checked  + label{
        background-color: red;
    }
    #videoSelectyTwitch:checked + label{
        background-color: #9146ff;
    }
    #videoSelectyLocal:checked + label{
        background-color: #fae5ab;
    }
    #videoSelectyLink:checked + label{
        background-color: rgb(255, 174, 0);
    }
}
////////////////////////////////////////////////////////
// 타이머 모듈 전용
////////////////////////////////////////////////////////

.TimeModule{
    p{
        margin: 0;
    }

}
.TimeModuleLayout{
    display:flex;
    // width: 100px;
    background-color: #222;
    border : solid 1px #fff;
    width: 90%;
    padding: 10px 5%;
    border-radius: 5px;
    justify-content: center;
    .timeInput{
        display: flex;

       input{
        display: inline-block;
        width : 40px;
        margin: 0px;
        border : none;
        border-bottom: solid 1px #fff;
       }
       span{
        display: inline-block;
        padding: 5px;
    }
    }
}


////////////////////////////////////////////////////////
// 필터에디터 모듈
////////////////////////////////////////////////////////

.FilterEdit{
    .InfoButtonModule:nth-of-type(1) img{
        filter: blur(2px);
    }
    .InfoButtonModule:nth-of-type(2) img{
        filter: grayscale(100%);
    }
    .InfoButtonModule:nth-of-type(3) img{
        filter: hue-rotate(180deg);
    }
    .InfoButtonModule:nth-of-type(4) img{
        filter: invert(100%);
    }
    .InfoButtonModule:nth-of-type(5) img{
        filter: saturate(300%);
    }
    .InfoButtonModule:nth-of-type(6) img{
        filter: sepia(100%);
    }
}

////////////////////////////////////////////////////////
// 그림자 에디터 모듈
////////////////////////////////////////////////////////
.ShadowEdit{
    .shadowDirectionModule{
        // transition: all 0.3s;
        .directionList{
            width: 100px;
        }
    }
    .useShadow{
        transition: all 0.3s;
    }
    .moduleHide{
        overflow: hidden;
        height: 0px;
        margin: 0px;
        padding: 0px;
    }
    .shadowButton{
        width: 26px;
        height: 26px;
        margin: 0px 2.5px;
    }

    .shadowEx{
        position: relative;
        margin: 3px;
        width: 13px;
        height: 13px;
        background-color: #999;
        transition: all ease-in 0.5s;
    }
    .shadowExLeftTop{
        top: 5px;
        left: 5px;
        filter: drop-shadow(-5px -5px 0px #000);
 
    }
    .shadowExLeftBottom{
        top: 0px;
        left: 5px;
        filter: drop-shadow(-5px 5px 0px #000);
    }
    .shadowExRightTop{
        top: 5px;
        left: 0px;
        filter: drop-shadow(5px -5px 0px #000);
    }
    .shadowExRightBottom{
        top: 0px;
        left: 0px;
        filter: drop-shadow(5px 5px 0px #000);
    }
    .shadowExCenter{
        top: 2.5px;
        left: 2.5px;
    }
    .shadowBlack{
        background-color: black;
    }

}

////////////////////////////////////////////////////////
// 정보버튼 모듈
////////////////////////////////////////////////////////

.InfoButtonModule{
    background-color: #222;
    border-radius: 5px;
    border : 1px solid white;
    margin: 5px 0;
    button{
        margin: 0;
        border: 0;
        padding: 0;
        background:none;
        color: white;
    }
    .dataArea{
        display: flex;

    }
    .textArea{
        width: 80%;
        display: flex;
        flex-direction: column;
    }
    img{
        display: flex;
        width: 25%;
        object-fit: cover;
        height: 100%;
    }
    h3{
        text-align: left;
        margin: 0 0 5px 0;
        width: 80%;
        margin: auto;
    }
    p{
        text-align: left;
        margin: 0 0 5px 0;

    }
    input[type=range]{
        display: flex;
        flex: 1;
        width: 80%;
        margin: auto;
      }
}

////////////////////////////////////////////////////////
//버튼 모듈
////////////////////////////////////////////////////////

//위젯 에서의 기본 버튼 


.ButtonModule{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px 0px;
    // height: 26px;
    p{
        font-size: 16px;
        margin: 0;
        width: 100px;
        line-height: 26px;
        padding: 0;
        text-align: left;
    }
    .ButtonList{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: nowrap;
        input[type="image"],.optionButton,Button,.button {
            
            width: 26px;
            height: 26px;
            margin-right: 5px;
            outline: none;
        
            transition: transform 0.1s ease, box-shadow 0.1s ease;
        }
        .optionButton{
            position: relative;
        }
        .optionButton:active{
            transform: scale(0.80);
        }
        .optionButton:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 10px;
            z-index: -1;
        }
        .optionButton:active:before {
            transform: scale(1.25);
       }
     
    }

    // 특별 프리셋
    &.roundWhite{
        .ButtonList{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            input[type="image"],.optionButton,Button,.button {
                border-radius: 5px;
                width: 20px;
                height: 20px;
                padding: 2.5px;
                background-color: #fff;
                // border: 1px solid #999;
                // background-color: yellow;
                filter: drop-shadow(rgba(0, 0, 0, 0.2) 5px 5px 5px) drop-shadow(rgba(0, 0, 0, 0.15) 10px 10px 10px);
                margin-left: 5px;
                outline: none;
            }
        }
    }
}




//이미지 버튼 
.ImageButtonModule{
    .optionButton.buttonSelect{
        .ButtonImage{
            display: flex;
            width: 100%;
            height: 100%;
            filter: drop-shadow(rgba(0, 0, 0, 0.5) 5px 5px 5px) drop-shadow(rgba(0, 0, 0, 0.03) 10px 10px 10px) 
        }
    }
    .ButtonImage{
        display: flex;
        width: 100%;
        height: 100%;
    }
}

.RangeModule{

    .valueView{
        display: flex;
        .startImage{
            img{
                width: 26px;
                height: 26px;
            }     
        }
        .endImage{
            img{
                width: 26px;
                height: 26px;
            }     
        }
    }
}

//이미지 버튼 
.ImageButtonModule{
    display: flex;
    flex-wrap: wrap;
    .optionButton.buttonSelect{
        .ButtonImage{
            display: flex;
            width: 100%;
            height: 100%;
            filter: drop-shadow(rgba(0, 0, 0, 0.5) 5px 5px 5px) drop-shadow(rgba(0, 0, 0, 0.03) 10px 10px 10px) 
        }
    }
    .ButtonImage{
        display: flex;
        width: 100%;
        height: 100%;
    }
}


////////////////////////////////////////////////////////
// 변형 모듈
////////////////////////////////////////////////////////
.RotateModule{
    .editNav{
        height: 26px;
        line-height: 26px;
    }
    .valueView{
        display: flex;
        .rotateText{
            line-height: 26px;
            // width: 26px;
            margin-right: 10px;
            height: 26px;
            border-bottom: 1px solid #d9d9d9;
            input{
                background-color: #444;
                width: 60px;
                height: 18px;
                padding: none;
                margin: none;
                // border-radius: 2px;
                color: #fff;
                border:none;

                font-size: 15px;
            }
        }
        .rotateButtonList{
            display: flex;
            
            .optionButton{
                width: 26px;
                height: 26px;
                outline: none;
                background-color: $EditModuleButton;
                background-color: #444;
                //box-shadow: 0 5px 5px rgba(20, 20, 20, 0.5), 0 3px 3px rgba(20, 20, 20, 0.3);
                border-radius: 2.5px;
                .ButtonImage{
                    display: flex;
                    width: 100%;
                    height: 100%;
                }
            }
    
            .optionButton:hover{
                background-color: rgba(255, 166, 0, 1) !important;
                box-shadow: none;
            }
    
        }
        
       

    }   
}

////////////////////////////////////////////////////////
//폰트 모듈
////////////////////////////////////////////////////////

.FontModule{
    .FontModuleButton{
        padding: 0px 7.5px;
        border-radius: 2.5px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
        span{
            line-height: 26px;
            margin :auto;
            
        }
        .FontModuleMore{
            padding-left: 5px;
            text-align: center;
            font-size: 10px;
            font-family: '프리텐다드Regular';
        }
    }
}


////////////////////////////////////////////////////////
//리스트 모듈
////////////////////////////////////////////////////////

.ListModule{
    margin: 5px 0;
    .listSearch{ 
        
        input{
        margin: auto;
        width: 100%; 
        background-color: #222;
        margin: 5px 0;
        box-sizing: border-box;
        color: #fff;
        border: 1px solid #d9d9d9;
        &::placeholder{
            color: #ffffffaa;
        }
        }
    }
    .listUp{
        max-height: 300px;
        transition: all 2s;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 8px;
            height: 2px;
            // background: #ffffff;
          }
        &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #555;
        background-clip: padding-box;
        border: 1px solid transparent;
        &:hover {
            background-color: orangered;
        }
        }
        &::-webkit-scrollbar-track {
            background-color: #444;
        }
    }
    .buttonList{
        outline: none;
        background: #222;
        border: 0;
        border-radius: 3px;
        margin: 5px 0px;
        width: 100%;
        height: 50px;
        display: flex;
        color:#fff;
        justify-content: space-between;
        &:hover{
            background: #666;
        }
        
        .listImg{
            display: flex;
            height: 40px;
            padding: 5px;
            object-fit: cover;
        }
        .listText{
            display: flex;
            flex: 1;
            flex-direction: column;
            
            h3{
                height: 20px;
                text-align: right;
                margin: 0;
                overflow: hidden;
                font-size: 18px;
                line-height: 20px;
            }
            p{
                height: 30px;
                text-align: right;
                margin: 0;
                overflow: hidden;
                font-size: 12px;
                line-height: 15px;
            }
        }

    }
}


////////////////////////////////////////////////////////
// 드레프트픽 에디터 지역설정
////////////////////////////////////////////////////////
.DraftPickEdit{
    .teamMember{
        background-color: #333;
        border-radius: 5px;
        padding: 5px 10px;
        margin: 5px 0px;
        .text{
            height: 13px;
            max-width: 100px;
        }
        .other{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .otherOption{

                display: flex;
                justify-content: flex-start;
                span{
                    margin-right: 10px;
                }
            }
            .delete{
                background-color: red;
                font-weight: bold;
                margin: 2.5px;
            }
        }

    }
}
////////////////////////////////////////////////////////
// 가상화폐 에디터 지역설정
////////////////////////////////////////////////////////
.CryptoCurrencyEdit{
    .newCoinAdd{
        background-color: orange;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        border :none;
        outline: none;
        text-align: center;
        &:hover{
            background-color: orangered;
        }
    }
    .newCoinCancel{
        background-color: orangered;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        border :none;
        outline: none;
        text-align: center;
        &:hover{
            background-color: orangered;
        }
    }
    .editMode{

    }
    .coinAlert{
        display: flex;
        justify-content: space-between;
    }
    .coinAddAlert{
        margin: 5px 0;
        padding: 5px;
        box-sizing: border-box;
        background-color: #333;
        border-radius: 5px;

    }

    .myCryptoCurrency{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin: 5px 0;
        padding: 5px;
        box-sizing: border-box;
        background-color: #333;
        border-radius: 5px;
        overflow: hidden;
        .upper{
            display: flex;
            width: 100%;
            justify-content: space-between;
            span{
                display: flex;
            }
            img{
                height: 15px;
                padding: 2.5px;
            }
            h3{
                font-size: 20px;
                height: 20px;
            }
        }
        .lower{
            p{
                font-size: 16px;
            }
            div{
                display: flex;
                justify-content: space-between;
                margin: 5px 0px;
            }
            input{
                background-color: #222;
                width: 100px;
                border-radius: 2px;
                color: #fff;
                border: 1px solid #d9d9d9;
            }

        }
    }
    .currencyDelect{
        background-color: orangered;
        border: none;
        color: white;
        outline: none;
        border-radius: 2px;
        &:hover{
            background-color: red;
        }
    }
}
.RadioButtonModule{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;

    input[type=radio] {
        // position: absolute;
        visibility: hidden;
        display: none;

    }
    
    label {
        color: white;
        display: inline-block;
        cursor: pointer;
        outline: none;
        font-weight: bold;
        // padding: 5px 20px;
        min-width: 60px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        font-size: 13px;
        text-shadow: 1px 1px 10px #333;
    }
    
    input[type=radio]:checked + label{
        font-weight: bold;
        background: #262626;
        border-radius: 5px;
        box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
    }
    
    input[type=radio] + label {
        background: #444;
        margin: 0px 2px;
        
        // box-shadow: 0 5px 5px rgba(20, 20, 20, 0.5), 0 3px 3px rgba(20, 20, 20, 0.3);
    }
    .RadioButtonList p {
        font-size: 16px;
        line-height: 26px;
        margin: 0;
        max-width: 100px;
        padding: 0;
        text-align: left;
    }
}
////////////////////////////////////////////////////////
// 리스트형 데이터 표준설정
////////////////////////////////////////////////////////
.normalListModule {

    .myDataList{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin: 5px 0;
        padding: 5px;
        box-sizing: border-box;
        background-color: #333;
        border-radius: 5px;
        overflow: hidden;
        .upper{
            display: flex;
            width: 100%;
            justify-content: space-between;
            span{
                display: flex;
            }
            img{
                height: 15px;
                padding: 2.5px;
            }
            h3{
                font-size: 20px;
                height: 20px;
            }
        }
        .lower{
            p{
                font-size: 16px;
            }
            div{
                display: flex;
                justify-content: space-between;
                margin: 5px 0px;
            }
            input{
                background-color: #222;
                width: 100px;
                border-radius: 2px;
                color: #fff;
                border: 1px solid #d9d9d9;
            }
    
        }
    }
    .dataAlert{
        display: flex;
        justify-content: space-between;
    }
    .dataAddAlert{
        margin: 5px 0;
        padding: 5px;
        box-sizing: border-box;
        background-color: #333;
        border-radius: 5px;
    
    }
    .newDataAdd{
        background-color: orange;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        border :none;
        outline: none;
        text-align: center;
        &:hover{
            background-color: orangered;
        }
    }
    .newDataCancel{
        background-color: orangered;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        border :none;
        outline: none;
        text-align: center;
        &:hover{
            background-color: orangered;
        }
    }
    .dataDelect{
        background-color: orangered;
        border: none;
        color: white;
        outline: none;
        border-radius: 2px;
        &:hover{
            background-color: red;
        }
    }
}
////////////////////////////////////////////////////////
// 테이블리스트형 데이터 표준설정
////////////////////////////////////////////////////////
.EditTableList{
    width: 100%;
    tr{
        padding-bottom: 5px ;
        width: 100%;
        height: 25px;
        display: flex;
        .delbut,.dumi{
            width: 30px;
            height: 25px;
            background-color: #00000000;
            padding: 0;
            margin: 0px;
            border: 0px;
            img{
                height: 25px;
                line-height: 25px;
                object-fit: scale-down;
                &:hover{
                    transform: rotate(90deg);
                    transition: transform 0.3s;
                }
            }
        }
        td{
            height: 25px;

            border: 0;
        }
        input{
            flex: 1;
            width: 100%;
            display:flex;
            height: 25px;
            padding: 0;
            box-sizing: border-box;
            background-color: #222;
            border: 1px solid #d9d9d9;
            color: #fff;
        }
        th{
            height: 25px;
            line-height: 25px;
            font-size: 20px;
            flex: 1;
            width: 100%;
            display:flex;
            padding: 0;
        }
    }
    .addList{
        background-color: orange;
        color: white;
        border: 0px;
        width: 100%;
        padding: 5px 0px;
        margin: 5px 0px;
        border-radius: 2px;
        &:hover{
            background-color:orangered;
        }
    }

}

////////////////////////////////////////////////////////
// 자르기 에딧
////////////////////////////////////////////////////////
.ClippathEdit{
    input{
        width: 30px;
    }
    .node{
        min-height: 25px;
        padding: 2.5px 5px ;
        margin: 2.5px 0px;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        background-color: #333;
        border-radius: 5px;
        .nodeVal{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-size: 13px;
        }
        .nodeCircleColor{
            display: flex;
            margin: 2.5px;
            width: 20px;
            height: 20px ;
            border-radius: 20px;
            box-sizing: border-box;
            border : 2px solid white;
        }
        input[type=number]{
            width: 30px;
            background-color: #222;
            padding: 0px 11px;
            border-radius: 2px;
            color: #000;
            border: 1px solid #d9d9d9;
        }
        span{
            margin: 2.5px 5px;
        }
        .nodeDelSpan{
            
        }
        .nodeDel{
            display: flex;
            width: 20px;
            height: 20px;
            margin: 2.5px;
            background-color: #666;
        }
        .node .nodeCircleColor:nth-child(1){
            background-color: palevioletred;
        }
        .node .nodeCircleColor:nth-child(2){
            background-color: blueviolet;
        }
        .node .nodeCircleColor:nth-child(3){
            background-color: teal;
        }
        .node .nodeCircleColor:nth-child(4){
            background-color: yellowgreen;
        }
        .node .nodeCircleColor:nth-child(5){
            background-color: brown;
        }
        .node .nodeCircleColor:nth-child(6){
            background-color: goldenrod;
        }
    }
}

////////////////////////////////////////////////////////
// 애니메이션
////////////////////////////////////////////////////////
.animationList{
    display: flex;
    flex-direction: column;
    .animationView{
        width: 200px;
        background-color: red;
        height: 200px;
    }
    .animationContainer{
        width: 200px;
        height: 200px;
        position: relative;
        // overflow: hidden;

        .animationSample{
            width: 200px;
            height: 200px;
            position: absolute;
            top:150px;
            left: 150px;
            flex-wrap: nowrap;
            display: flex;
        //  width: 50%;
        //  height: 50%;
        .animationImg{
            width: 200px;
            height: 200px;
            display: flex;
            img{
                width: 100px;
                height: 100px;
            }
        }
        }

    }

}
// .viewTEst{
// background-image: url("http://localhost:3000/static/media/logo.900efef2.svg");

// }



#WidgetSet{

    h5{
        margin: 0;
        font-size: 16px;
    }
    h6{
        margin: 0;
    }

    .MediaSourceEditModule{
        // display: flex;
        // flex-direction: column;
        .MediaSourcePreview{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            border-radius: $Editradius;
            overflow: hidden;
            .MediaSourceImgPreviewField{
                position: relative;
                .MediaSourceImgPreview{
                    display: flex;
                    box-sizing: border-box;
                    border : $EditGuideLine;
                 }
                 .MediaSourceImgPreviewLink{
                    position: absolute;
                    top : 0;
                    right: 0;
                    margin: 5px;
                    border-radius: 5px;
                    .optionButton{
                        padding: 2.5px;
                    }
                 }
            }
          
        }

    }
    .FileUploadEditModule{
        width: 100%;

        .FileUpload{
            display: none;
        }
        .FileUploadButton{
            display: flex;
            padding: 0px 10px;
            border-radius: 5px;
            height: 100%;
            background: #444;
            box-shadow:  3.5px 3.5px 10px #252525,
                        -3.5px -3.5px 10px #636363;
            font-size: 14px;
            line-height: 20px;
            &:hover{
                background: #262626;
            }
        }
    }

    .appendImageButton{
        width: 100%;
        margin: 0px 0px;
        padding: 5px 0px;
        display: flex;
        justify-content: space-between;
        // background-color: #000;
        .appendImageIcon{
            display: flex;
            width: 20px;
            height: 20px;
            align-items: center;
            justify-content: center;
            background: #444;
            box-shadow:  3.5px 3.5px 10px #252525,
                        -3.5px -3.5px 10px #636363;
            border-radius: 999px;
            img{
                display: flex;
                width: 75%;
                height: 75%;
                align-items: center;
                justify-content: center;
                object-fit: scale-down;
            }
        }
        &:hover{
            .appendImageIcon{
                background: #262626;
            }
        }
    }

}

////////////////////////////////////////////////////////
// 미디어 소스
////////////////////////////////////////////////////////


.MediaInfoModule{

}

.ParticlesEdit{
    display: flex;
    flex-direction: column;
    .ParticlesExampleBox{
        width: 100%;
        height: 50px;
        margin: 5px 0px;
        padding: 5px 0px;
        border-radius: 5px;
        background: #222;
        box-shadow:  3.5px 3.5px 10px #252525,
                    -3.5px -3.5px 10px #636363;
        display: flex;
        justify-content: center;
        display: flex;
        flex-direction: row;
        position: relative;
        .ParticlesExample{
            // position: absolute;
            width: 50px;
            height: 50px;
        }
  
        // .ParticlesExample:nth-child(0){
        //     top: 50px;
        // }
        // .ParticlesExample:nth-child(1){
        //     top: 25px;
        // }
        // .ParticlesExample:nth-child(2){
        //     top: 50px;
        // }
    }
    select{
        background-color: #444;
        border: none;
        color: white;
        font-family: '프리텐다드ExtraBold';
        border-radius: 5px;
        height: 26px;
        padding: 0px 5px;
        box-shadow:  3.5px 3.5px 10px #252525,
                    -3.5px -3.5px 10px #636363;
    }
}


.CaptureStreamEdit{

    .MediaSourceModule{
        max-height: 300px;
        overflow: auto;
    }

    .streamList{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: 70px;
        background-color: #333;
        border: solid 1px #333;
        width: calc(100% - 20px);
        border-radius: 5px;
        margin: 10px 0px;
        padding: 10px;
    }
    .selectStream{
        border: solid 1px red;
    }
    .streamInfo{
        margin-left: 10px;
    }
    .streamNoti{
        background-color: red;
        font-size: 12px;
        padding: 2px;
        margin-top: 5px;
        border-radius: 5px;
    }
    .streamBox{
        flex : 1 ;
        height: 70px;
        
    }
    .VideoStream{
        width: 100%;
        height: 100%;
    }

    

    .VideoStream video{
        object-fit: contain;
        // display: flex;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        // box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
    }

}


//

.VariableTeam {
    font-family: "프리텐다드SemiBold", sans-serif;
    border-radius: 5px;
    background: #444444;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;

    margin : 20px 0px;
    padding : 0px;
    overflow :hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.defaultField{
    display: flex;

}
  @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
.VariableTeam .ScoreboardNoti{
    position: absolute;
    display : flex;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: #00000040;
    backdrop-filter: blur(2px);
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease forwards; 
}

.VariableTeam .ScoreboardNoti .NotiInfo{
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.VariableTeam .ScoreboardNoti .NotiInfo h3{
    background-color: #00000090;
    padding : 10px ; 
    margin : 10px 0px;
    border-radius: 5px;
    display : flex;
    line-height : 30px;
}

.VariableTeam .ScoreboardNoti .NotiInfo .deleteTeam{
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border : 1px solid rgba(255,0,0,1);
    padding : 10px ; 
    margin : 10px 0px;
    border-radius: 5px;
}

.VariableTeam .handle{
    display : flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 0px 0px 0px 5px;
}
.VariableTeam .TeamImage{
    background: #222;
    height: 50px;
    width: 50px;
    padding : 7.5px;
    margin : 7.5px;
    border-radius: 5px;

}

.VariableTeam .TeamImage img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;

}

.VariableTeam .TeamColor input[type="color"] {
    /* 크기를 20px로 설정 */
    width: 20px;
    height: 20px;

    /* 원형 모양으로 만듬 */
    border-radius: 50%;

    /* 경계선 및 패딩 제거 */
    border: 2px solid rgb(114, 114, 114);
    padding: 0;

    /* 커서 모양 변경 */
    cursor: pointer;

    /* 컬러 피커의 화살표 제거 */
    -webkit-appearance: none;

    /* 내부 요소의 크기 조정 */
    overflow: hidden;
}

/* 내부 아이콘 숨기기 및 크기 조정을 위한 추가 스타일 */
.VariableTeam .TeamColor input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    width: 20px;
    height: 20px;
}

.VariableTeam .TeamColor input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

.VariableTeam .TeamInfo{
    display: flex;
    flex-direction: column;
    flex : 1;
    margin : 0px ; 
    justify-content: space-between;
    
}

.VariableTeam .TeamName{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    
}

.BanPickModule .BanPickTimer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size : 18px;
    margin : 5px 7.5px;
}

.BanPickModule .editRow{
    margin : 5px 7.5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.BanPickModule .BanPickTimerInput {
    font-size: 20px;
    height: 20px;
    width: 50px;
    text-align: right;
    background-color: #222;
    border-radius: 2px;
    color: #fff;
    border: 1px solid #727272;
}


.VariableTeam .TeamPoint{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size : 18px;
    margin : 5px 7.5px;
}

.VariableTeam .editRow{
    margin : 5px 7.5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.VariableTeam .TeamPointInput {
    font-size: 20px;
    height: 20px;
    width: 50px;
    text-align: right;
    background-color: #222;
    border-radius: 2px;
    color: #fff;
    border: 1px solid #727272;
}

.VariableTeam .TeamColor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size : 18px;
    margin : 5px 7.5px;
}


.VariableTeam .TeamNameInput {
    background-color: #444444;
    padding: 9.5px 0px 2px 0px;
    border-radius: 2px;
    color: #fff;
    border: none;
    font-family: '프리텐다드SemiBold';
    width : 150px;
    font-size : 20px;
    margin-left : 7.5px;
}

.VariableTeam .TeamNameInput::placeholder {
    color: #BBB;

}

.VariableTeam .TeamLogoInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.VariableTeam .TeamLogoInput{
    font-size: 10px;
    height: 20px;
    width: 150px;
    text-align: right;
    background-color: #222;
    border-radius: 2px;
    color: #fff;
    border: 1px solid #727272;
    text-align: start;
}



.VarTeam{
    font-family: "프리텐다드SemiBold", sans-serif;
    border-radius: 5px;
    background: #333;
    transition: transform 0.5s;
    width: 240px;
    min-height: 50px;
    margin : 20px 0px 0px 0px;
    padding : 0px;
    overflow :hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
    .TeamHeader{
        height: 50px;
        display: flex;
        .TeamLogo{
            height: 100%;
            width: 50px;
            background: #222;
            img{

                display: flex;
                margin : 5px;
                height: 40px;
                width: 40px;
                object-fit: scale-down;
                border-radius: 5px;
            }
        }
        .TeamLogoLeft{
            height: 100%;
            width: 20px;
        }
        .TeamName{
            line-height: 50px;
            font-size: 20px;
            flex:1;
        }
        .TeamOption{
            position: absolute;
            display: flex;
            justify-content: end;
            right: 0px;
            height: calc(100% - 10px);
            margin: 5px;
            width: 50px;
            button{
    
                width: 20px;
                height: 20px;
                background: #333;
                border-radius: 30px;
                box-shadow: 3.5px 3.5px 10px #00000050;
            }
        }
    }
    .TeamContent{
        min-height: 0px;
        background: #333;
        display: flex;
        flex-wrap: wrap;
        .ScoreBox{
            height: 50px;   
            width: 50px;
            margin: 0px 10px;
            .ScoreTitle{
                width: 50px;
                height: 20px;
            }
            .ScoreInput{
                height: 20px;
                width: 50px;
                .TeamPointInput{
 
                    font-size: 20px;
                    height: 20px;
                    width: 50px;
                    text-align: right;
                    background-color: #222;
                    border-radius: 2px;
                    color: #fff;
                    border: 1px solid #727272;
                }
            }
            
        }
    }
    &.select{
        transform: scale(1.06);
        // border: 2px solid #fff;

    }
}


.oop{

}