// 프리셋

p,h3{
    margin: 0;
}
.CryptoCurrency_preset_normal{
    background-color: #444;
    border-radius: 10px;
    overflow: hidden;
    color: white;
    font-family: '에스코어드림Bold';
    display: flex;
    height: 100%;
    flex-direction: column;
    .coinUpper{
        background-color: #666;
        height: 50%;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        .coinData{
            display: flex;
            width: 100%;
            padding: 0 5%;
            img{
                object-fit: scale-down;
                height: 80%;
                margin: auto 0;
            }
            .name{
                padding: 0 5%; 
                overflow: hidden;
            }
        }

    }
    .coinLower{
        height: 50%;
        display: flex;
        padding: 0 5%;
        justify-content: space-between;
        .tradePrice{
            
        }
        .changePercent{

        }
        .long{
            color: rgb(247, 115, 151);
        }
        .short{
            color: rgb(89, 203, 232);
        }
    }

}
