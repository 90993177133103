// 프리셋
.Clock{

}

.Clock_preset_normal{

}

.Clock_preset_textStyle{

    display: flex;
    j
    .ClockText{
        display: flex;
    }
    .ClockText.colon{
        padding: 0px;
    }
    .ClockText.colon:last-child{
        display: none;
        padding: 0px;
    }
    .ClockTimer{

    }
    .ClockText.mdText{
        padding: 20px;
    }
}

.Clock_preset_flipStyle{

    .ClockWrap{
        display: flex;
        // background-color: red;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .ClockText{
        display: flex;
   
    }
    .ClockText.enText{
        align-items: flex-end;
        justify-content: flex-start;
        // text-align: center;
    }
    .ClockText.krText{
        align-items: flex-end;
        justify-content: flex-start;
        // text-align: center;
    }
    .ClockText.colon{
        align-items: center;
        justify-content: center;
        // text-align: center;
        padding: 0px;
    }
    .ClockText.colon:last-child{
        display: none;
        padding: 0px;
    }
    .ClockTimer{
        display: flex;
    }
    .ClockText.mdText{
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .card.updatetrue{

        .cardTop{
            .cardNum{
                // animation: NumTop 1s linear 0s infinite forwards;
            }
        }

        .cardFlip{
            background-color : #444;
            animation: Flip 1s linear 0s infinite;
            .cardNumFront{
                animation: NumFront 1s linear 0s infinite forwards;
            }
            .cardNumBack{
                animation: NumBack 1s linear 0s infinite forwards;
            }
        }
        .cardBot{
            .cardNum{
            // animation: NumBot 1s linear 0s infinite forwards;
            }
        }
    }
    .cardWrap{

        display: flex;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;

        .check2{
            position: absolute;
            z-index: 999;
            font-size: 15px;
            color: black;
        }
        .card{
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 20px;
            overflow : hidden;
            text-align: center;
            background-color :#444;
            
            .cardNum{
                width: 100%;
                height: 100%;
                display :flex;
                justify-content: center;
                align-items: center;
                overflow :hidden;
            }
    
    
            .cardTop{
                width: 100%;
                height: 100%;
                position: absolute;
                background-color :#444;
                z-index : 1;
                overflow: hidden;
                // box-shadow: 0px 5px 5px black;
                clip-path: inset(0 0 50% 0);
            }
            .cardFlip{
                box-shadow: 0px -100px 100px black;
        
                width: 100%;
                height: 100%;
                transform-origin : bottom; 
                position: absolute;
                background-color : transparent;
                z-index : 9;
                overflow: hidden;
                transform-origin: center;
                .cardNumFront{
                    width: 100%;
                    height: 100%;
                    position: absolute;
              
                    background-color :#444;
                    clip-path: inset(0 0 50% 0);
                }
                .cardNumBack{
                    width: 100%;
                    height: 100%;
                    box-shadow: 0px 100px 300px black;
                    clip-path: inset(50% 0 0 0);
                    background-color : #444;
                    opacity: 0;
                    position: absolute;

                    transform: rotateZ(0.5turn) rotateY(0.5turn);
                
                }
    
            }
    
            .cardBot{
                clip-path: inset(50% 0 0 0);
                width: 100%;
                height: 100%;
                position: absolute;
                background-color : #444;
                z-index : 0;
                overflow: hidden;
            }
            @keyframes NumFront {
                0% {  
                opacity: 1;
                }
                49%{
                opacity: 1;
                }
                50% {
                opacity: 0;
                }
                100% {
                opacity: 0;
                }
            }
            
            @keyframes NumBack {
                0% {  
                opacity: 0;
                }
                49%{
                opacity: 0;
                }
                50% {
                opacity: 1;
                }
                100% {
                opacity: 1;
                }
            }

            // @keyframes NumTop {
            //     0% {  
            //     opacity: 0;
            //     }
            //     1% {  
            //     opacity: 0;
            //     }
            //     10%{
            //     opacity: 1;
            //     }
            //     100% {
            //     opacity: 1;
            //     }
            // }

            // @keyframes NumBot {
            //     0% {  
            //     opacity: 1;
            //     }
            //     49%{
            //     opacity: 1;
            //     }
            //     50% {
            //     opacity: 0;
            //     }
            //     100% {
            //     opacity: 0;
            //     }
            // }


            
            @keyframes Flip {
                0% {  
                    transform: rotateX(0deg);
                }
                25% {  
                    // opacity: 0;
                    transform: rotateX(0deg);

            
                }
                50% {
                    transform: rotateX(90deg);

                }
                100% {
                    transform: rotateX(180deg);
                }
                
            
            }
        }
    }
   
}
 


.Clock_preset_flipWhiteStyle{

    .ClockWrap{
        display: flex;
        // background-color: red;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .ClockText{
        display: flex;
   
    }
    .ClockText.enText{
        align-items: flex-end;
        justify-content: flex-start;
        // text-align: center;
    }
    .ClockText.krText{
        align-items: flex-end;
        justify-content: flex-start;
        // text-align: center;
    }
    .ClockText.colon{
        align-items: center;
        justify-content: center;
        // text-align: center;
        padding: 0px;
    }
    .ClockText.colon:last-child{
        display: none;
        padding: 0px;
    }
    .ClockTimer{
        display: flex;
    }
    .ClockText.mdText{
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .card.updatetrue{

        .cardTop{
            .cardNum{
                // animation: NumTop 1s linear 0s infinite forwards;
            }
        }

        .cardFlip{
            background-color : #fff;
            animation: Flip 1s linear 0s infinite;
            .cardNumFront{
                animation: NumFront 1s linear 0s infinite forwards;
            }
            .cardNumBack{
                animation: NumBack 1s linear 0s infinite forwards;
            }
        }
        .cardBot{
            .cardNum{
            // animation: NumBot 1s linear 0s infinite forwards;
            }
        }
    }
    .cardWrap{

        display: flex;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;

        .check2{
            position: absolute;
            z-index: 999;
            font-size: 15px;
            color: black;
        }
        .card{
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 20px;
            overflow : hidden;
            text-align: center;
            background-color : #fff;
            
            .cardNum{
                width: 100%;
                height: 100%;
                display :flex;
                justify-content: center;
                align-items: center;
                overflow :hidden;
                background-image: url();
            }
    
    
            .cardTop{
                width: 100%;
                height: 100%;
                position: absolute;
                background-color : #fff;
                z-index : 1;
                overflow: hidden;
                // box-shadow: 0px 5px 5px black;
                clip-path: inset(0 0 50% 0);
            }
            .cardFlip{
                box-shadow: 0px -100px 100px black;
        
                width: 100%;
                height: 100%;
                transform-origin : bottom; 
                position: absolute;
                background-color : transparent;
                z-index : 9;
                overflow: hidden;
                transform-origin: center;
                .cardNumFront{
                    width: 100%;
                    height: 100%;
                    position: absolute;
              
                    background-color : #fff;
                    clip-path: inset(0 0 50% 0);
                }
                .cardNumBack{
                    width: 100%;
                    height: 100%;
                    box-shadow: 0px 100px 300px black;
                    clip-path: inset(50% 0 0 0);
                    background-color : #fff;
                    opacity: 0;
                    position: absolute;

                    transform: rotateZ(0.5turn) rotateY(0.5turn);
                
                }
    
            }
    
            .cardBot{
                clip-path: inset(50% 0 0 0);
                width: 100%;
                height: 100%;
                position: absolute;
                background-color : #fff;
                z-index : 0;
                overflow: hidden;
            }
            @keyframes NumFront {
                0% {  
                opacity: 1;
                }
                49%{
                opacity: 1;
                }
                50% {
                opacity: 0;
                }
                100% {
                opacity: 0;
                }
            }
            
            @keyframes NumBack {
                0% {  
                opacity: 0;
                }
                49%{
                opacity: 0;
                }
                50% {
                opacity: 1;
                }
                100% {
                opacity: 1;
                }
            }

            // @keyframes NumTop {
            //     0% {  
            //     opacity: 0;
            //     }
            //     1% {  
            //     opacity: 0;
            //     }
            //     10%{
            //     opacity: 1;
            //     }
            //     100% {
            //     opacity: 1;
            //     }
            // }

            // @keyframes NumBot {
            //     0% {  
            //     opacity: 1;
            //     }
            //     49%{
            //     opacity: 1;
            //     }
            //     50% {
            //     opacity: 0;
            //     }
            //     100% {
            //     opacity: 0;
            //     }
            // }


            
            @keyframes Flip {
                0% {  
                    transform: rotateX(0deg);
                }
                25% {  
                    // opacity: 0;
                    transform: rotateX(0deg);

            
                }
                50% {
                    transform: rotateX(90deg);

                }
                100% {
                    transform: rotateX(180deg);
                }
                
            
            }

            @keyframes test {
                to{
                    transform: translate3d(200px, -80px, 0) rotate(-40deg) skewX(70deg) scale(1.5);
                    text-shadow: 0 0 20px whitesmoke;
                    opacity: 0;
                }
                
            }

            @keyframes smoky {
                to {
                  transform: 
                    translate3d(200px, -80px, 0)
                    rotate(-40deg)
                    skewX(70deg)
                    scale(1.5);
                  text-shadow: 0 0 20px whitesmoke;
                  opacity: 0;
                }
              }

        }
    }
   
}
 
  

  

  

  
  
