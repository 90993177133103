#ViewPage{
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
}

#ViewScreenField{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
}

#ViewContorol{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;
    top:0;
    background-color: #323232;
}