
#GuideBook {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size:17px;
    line-height: 20px;
  }

  #GuideBook .infoImage{
    display: flex;
    margin : 20px;
    border-radius : 5px;
    width: 80%;
    overflow : hidden;
  }


  #GuideBook h3{
    margin : 20px;
    font-size : 25px;
  }

  #GuideBook .infoImage img{
    display: flex;
    border-radius : 5px;
    width: 100%;
    object-fit: scale-down;
  }

  #GuideBook .infoMessage{
    background-color: #666;
    margin : 20px;
    border-radius : 5px;
    padding : 10px;
    
  }

  #GuideBook b{
    font-weight: bold;
    font-family: '프리텐다드ExtraBold';
  }
  #GuideBook p{
    margin : 10px;

  }

  #GuideBook .iconBox{
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius : 5px;
    margin : 0px 10px;
  }
  #GuideBook .icon{
    display: inline;
  }