@mixin fontSmoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
}

#ShowPage{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    font-family: '프리텐다드SemiBold';

    @include fontSmoothing;
    .screenWrap{
  
    }
    .hoverView{
        position: absolute;
        bottom: 10vh;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        transition: opacity 0.3s;
    }
    .hoverNav{
        background-color: #222;
        width: 500px;
        padding: 0 50px;
        height: 50px;
        color: white;
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        li{
            list-style: none;
            margin: 0 20px;
            line-height: 50px;
        }
        a{
            color: white;
            text-decoration: none;
        }
        span{
            display: flex;
        }
    }
    .opacity{
        opacity: 0;
    }
}