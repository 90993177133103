// 프리셋
p,h3,pre{
    margin: 0;
}

.BackgroundPatternDesign_preset_patternDesign{

    position: relative;
    overflow: hidden;
    .patternDesign{
        // animation: 1s ease 0s 1 normal none running patternDesignR;
        animation-iteration-count: infinite;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        .pattern{
            // background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
    }
    .patternDesignView{
        width: 200%;
        height: 200%;
        top: -50%;
        left: -50%;
        background-size: 19% 19%;
        background-repeat: space;
        background-position: initial;
        // border: inset 10px transparent;
        // box-sizing: border-box;
    }
}


@keyframes patternDesignTopLeft {
    0%{
        transform: translate(-250px,-0px) ;
    }
    100%{
        transform: translate(0px,-250px) ;
    }
}

@keyframes patternDesignTopRight {
    0%{
        transform: translate(-250px,-250px) ;
    }
    100%{
       
    }
}


@keyframes patternDesignBottomRight {
    0%{

    }
    100%{
        transform: translate(-250px,-250px) ;
    }
}

@keyframes patternDesignBottomLeft {
    0%{
        transform: translate(0px,-250px) ;
    }
    100%{
        transform: translate(-250px,-0px) ;
    }
}


@keyframes patternDesignMove {
    from { top:-200px; left: -187.5px; transform: rotate(0deg) translate(-62.5px,-62.5px) rotate(0deg); }
    to   { top:-200px; left: -187.5px;transform: rotate(360deg) translate(-62.5px,-62.5px) rotate(-360deg); }
}
