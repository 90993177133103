// 프리셋
p,h3,pre{
    margin: 0;
}
.LiveIcon_preset_circle{
    box-sizing: border-box;
    border : solid 2px red;
    display: flex;
    .liveText{
        color: red;
    }
    .liveIcon{
        background-color: red;
        width: 100%;
        
    }
}
