
    @import url('https://fonts.googleapis.com/css2?family=Syne:wght&display=swap');
    ////////////////////////////////////////////////////////
    // 머터리얼 아이콘
    ////////////////////////////////////////////////////////

    *{
        font-display: fallback;
         
    }
    ////////////////////////////////////////////////////////
    // 나눔 폰트
    ////////////////////////////////////////////////////////
    @font-face {
        font-family: '나눔스퀘어';
        font-weight: 400;
        src: url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/NanumSquareR.eot);
        src: url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/NanumSquareR.eot?#iefix) format('embedded-opentype'),
             url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/NanumSquareR.woff) format('woff'),
             url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/NanumSquareR.ttf) format('truetype');
    }
    @font-face {
        font-family: '나눔고딕';
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/nanumgothic/v17/PN_3Rfi-oW3hYwmKDpxS7F_z-9LZxnthzY0SO7Jj27F-wOe3JI_cmfgf-Q.0.woff2) format('woff2');
      }
    @font-face {
        font-family: '나눔바른고딕';
        font-style: normal;
        font-weight: 400;
        src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot');
        src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf') format('truetype');
       }
    @font-face {
        font-family: '나눔바른펜';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumBarunpen.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    ////////////////////////////////////////////////////////
    // 배민폰트
    ////////////////////////////////////////////////////////
    @font-face {
        font-family: '한나체';
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/ea/hanna/v3/BM-HANNA.eot);
        src: url(https://fonts.gstatic.com/ea/hanna/v3/BM-HANNA.eot?#iefix) format('embedded-opentype'),
             url(https://fonts.gstatic.com/ea/hanna/v3/BM-HANNA.woff2) format('woff2'),
             url(https://fonts.gstatic.com/ea/hanna/v3/BM-HANNA.woff) format('woff'),
             url(https://fonts.gstatic.com/ea/hanna/v3/BM-HANNA.ttf) format('truetype');
      }

    @font-face {
        font-family: '한나체Air';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.0/BMHANNAAir.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: '주아체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMJUA.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: '기랑해랑체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMKIRANGHAERANG.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: '도현체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMDOHYEON.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: '연성체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMYEONSUNG.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: '을지로체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/BMEULJIRO.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: '을지로10년후체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.0/BMEuljiro10yearslater.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    ////////////////////////////////////////////////////////
    // 넥슨폰트
    ////////////////////////////////////////////////////////
    @font-face {
        font-family: '메이플스토리체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/MaplestoryOTFBold.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    
    ////////////////////////////////////////////////////////
    // 에스코어 폰트
    ////////////////////////////////////////////////////////
    @font-face {
        font-family: '에스코어드림Thin';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-1Thin.woff') format('woff');
        font-weight: normal;
        font-style: normal;
   }
   @font-face {
        font-family: '에스코어드림Light';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '에스코어드림Medium';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '에스코어드림Bold';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff') format('woff');
        font-weight: normal;
        font-style: normal;
   }
   @font-face {
        font-family: '에스코어드림ExtraBold';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-7ExtraBold.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '에스코어드림Heavy';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff') format('woff');
        font-weight: normal;
        font-style: normal;
   }
   @font-face {
        font-family: '에스코어드림Black';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    ////////////////////////////////////////////////////////
    // 프리텐다드
    ////////////////////////////////////////////////////////
    @font-face {
        font-family: '프리텐다드Thin';
        src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff') format('woff');
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: '프리텐다드Light';
        src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: '프리텐다드Regular';
        src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: '프리텐다드Medium';
        src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: '프리텐다드SemiBold';
        src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: '프리텐다드ExtraBold';
        src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: '프리텐다드Black';
        src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
    }
    ////////////////////////////////////////////////////////
    // 어그로체
    ////////////////////////////////////////////////////////
    @font-face {
        font-family: '어그로체Light';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroL.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '어그로체Medium';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroM.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '어그로체Bold';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    ////////////////////////////////////////////////////////
    // 기타 기업폰트
    ////////////////////////////////////////////////////////
    @font-face {
        font-family: '삼립호빵체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts-20-12@1.0/SDSamliphopangche_Outline.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '잘난체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '이사만루체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/GongGothicMedium.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '마포꽃섬';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/MapoFlowerIslandA.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: '조선궁서체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunGs.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'DOS명조';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_eight@1.0/DOSMyungjo.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '타자기체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2102-01@1.0/KoreanFrenchTypewriter.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '당당해체';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.2/Cafe24Dangdanghae.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '교보손글씨';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/KyoboHand.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: '망고빙수';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2106@1.1/SF_IceMango.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }