////////////////////////////////////////////////////////
// 전역설정
////////////////////////////////////////////////////////

.widgetObject{
    display: flex;
    position: absolute;
    // background-color: red;
    -webkit-user-drag : element;
    user-select: none;
    outline: none;
}

.widgetObject .object.unknown{
    background:linear-gradient(to right, #2C5364, #203A43, #0F2027);
    border-radius:10px;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
}

.widgetObject.edit.widgetHidden>*:not(.overRay) { 
    opacity: 0.3;
}
.widgetObject.broadcast.widgetHidden>*:not(.overRay) { 
    // display: none;
    opacity: 0;
}
.widgetObject.view.widgetHidden>*:not(.overRay) { 
    opacity: 0;
}

.unknownPreset{
    background-color: #444;
    color: white !important;
    background-image: url('../assets/edit/widgetList.png');
    border-radius: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100px 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    p{
        margin-bottom: 130px;
        font-size: 20px;
    }
}

.ScrollCaption{
    .marquee{
        position: relative;
        overflow: hidden;
        .marqueeText{
            word-break: keep-all;
            white-space: nowrap;
            position: absolute; 
            display: flex;
            height: 100%;
            animation: marquee 5s linear infinite;
        }
    }

}
@keyframes marquee {
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
  }


 @keyframes colorChange{
    0%{
        background-position: 0% 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0% 50%;
    }
}


.atsatsat{
    justify-content: center;
    align-items: center;
    flex-direction: column;
}