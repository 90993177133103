#MainPage{
    background-color: #1c1c1c;
    min-height: 1080px;
    // width: 100vw;
    font-family: '나눔스퀘어';
    header{
        background-color: orange;
        // width: 100vw;
        height: 48px;
        display: flex;
        color: white;
        nav{
            display: flex;
            width: 1200px;
            margin: 0px auto;
            line-height: 48px;
        }
        #MainPageLogo{
            display: flex;
            color: #fff;
            .mainLogo{
                height: 40px;
                margin: 5px 5px 5px 0px;

            }
            h1{
                margin: 0px;
                font-size: 30px;
                line-height: 50px;
                font-family: 'Syne', sans-serif;
                font-weight: 700;
            }
        }
        #nav{
            margin: 0;
            flex: 1;
            list-style: none;
            display: flex;
            li a{
                padding: 10px;
                color: white;   
                text-decoration: none;
                cursor: pointer;
            }
        }
        button{
            background-color: #00000000;
            color: white;   
            border: none;
            outline:none;
        }
    }
    
    main{
        a { text-decoration: none; color: #fff; }
        a:visited { text-decoration: none; }
        a:hover { text-decoration: none; }
        a:focus { text-decoration: none; }
        a:hover, a:active { text-decoration: none; }
        max-width: 1200px;
        margin: 0px auto;
        color: white;
        text-align: center;
        .banner{
            margin: 20px 0;
            max-height: 300px;
            overflow: hidden;
            background-color: #222;
            .tutorial{
                width: 100%;
                
                background-color: orange; 
                font-size: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                p{
                    margin: 0;
                }
                img{

                }
            }
        }
        .cardList{
            margin: 20px 0;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .actionCard
            {
                width: 400px;
                margin: 10px;
                height: 150px;
                background-color: #444;
                border-radius: 10px;
                border: 25px solid #444;
                display: flex;
                .cardImg{
                    width: 100px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    img{
                       width: 100px;
                       align-items: flex-end;
                       object-fit: scale-down;
                    }
                }
                .cardText{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    h3,a,p{
                        margin: 0;
                    }
                    
                    .cardName{
                        text-align: right;
                        font-size: 35px;
                    }
                    .cardInfo{
                        text-align: right;
                        font-size: 17.5px;
                    }
                }
            }
        }
    }
}
