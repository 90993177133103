// ////////////////////////////////////////////////////////
// // 스코어 보드 애니메이션
// ////////////////////////////////////////////////////////

/* common 애니메이션 */

@keyframes widget_animation_fadeIn{
    0% { opacity: 0; }
    100% { opacity: 1; }
}


@keyframes widget_animation_zoomIn{
    0% { transform: scale(0.5); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}

@keyframes widget_animation_scaleUp{
    0% { transform: scale(1.5); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}

@keyframes widget_animation_slideIn{
    0% { transform: translateX(10vw); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes widget_animation_slideIn_Left{
    0% { transform: translateX(100vw); opacity: 0; }
    1% { transform: translateX(100vw); opacity: 1; }
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes widget_animation_slideIn_Right{
    0% { transform: translateX(-100vw); opacity: 0; }
    1% { transform: translateX(-100vw); opacity: 1; }
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes widget_animation_slideIn_Top{
    0% { transform: translateY(-100vw); opacity: 0; }
    1% { transform: translateY(-100vw); opacity: 1; }
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes widget_animation_slideIn_Bottom{
    0% { transform: translateY(100vw); opacity: 0; }
    1% { transform: translateY(100vw); opacity: 1; }
    100% { transform: translateX(0); opacity: 1; }
}

// @keyframes widget_animation_slideIn_Bottom{
//     0% { transform: translateX(100vw); opacity: 0; }
//     1% { transform: translateX(100vw); opacity: 1; }
//     100% { transform: translateX(0); opacity: 1; }
// }

@keyframes widget_animation_RotateIn{
    0% { transform: rotateZ(360deg) scale(0); opacity: 0; }
    100% { transform: rotateZ(0deg) scale(1); opacity: 1; }
}



// ////////////////////////////////////////////////////////
// // 스코어 보드 애니메이션
// ////////////////////////////////////////////////////////
// @keyframes moveLeft{
//     0%{
//         transform: translate(-100%,40vh) scale(2);
//     }
//     20%{
//         transform: translate(-20%,40vh) scale(2);
//     }
//     70%{
//         transform: translate(-20%,40vh) scale(2);
//     }
//     100%{
//     }
// }
// @keyframes moveMid{
//     0%{
//         transform: translate(0,100vh) scale(2);
//     }
//     20%{
//         transform: translate(0,60vh) scale(2);
//     }
//     70%{
//         transform: translate(0,60vh) scale(2);
//     }
//     100%{
//     }
// }


// @keyframes moveRight{
//     0%{
//         transform: translate(100%,40vh) scale(2);
//     }
//     20%{
//         transform: translate(20%,40vh) scale(2);
//     }
//     70%{
//         transform: translate(20%,40vh) scale(2);
//     }
//     100%{
//     }
// }


// @keyframes MatchTitle{
//     0%{
//         transform: translate(25vw,0) scale(2);
//     }
//     70%{
//         transform: translate(25vw,0) scale(2);
//     }
//     100%{
//     }
// }
// @keyframes MatchList{
//     0%{
//         transform:rotateX(90deg);
//     }
//     50%{
//         transform:rotateX(90deg);
//     }

//     100%{
//     }
// }

// @keyframes TeamRight{
//     0%{
//         transform: translate(100vw,0)
//     }

//     100%{
//     }
// }