#OldLoginPage { 
    font-family: "프리텐다드SemiBold", sans-serif;
    background-color: #202020;
    height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    main{
        width: 1000px;
        height: 500px;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        #infoBox{
            width: 500px;
            height: 500px;
            background-color: orange;
            img{
                width: 300px;
                height: 300px;
                margin: 100px;
            }
            text-align: center;
            font-size: 30px;
        }

        #LoginBox{
            width: 100%;
            height: 500px;
            background-color: whitesmoke;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            
            
            #LoginBoxInfo{
                font-size: 50px;
                color: #444;
                font-weight: bold;
                text-align: center;
                height: 100px;
                line-height: 100px;
            }

            #LoginBoxInput{
                padding: 0;
                margin: 50px 50px;

                div{
                    display: flex;
                    width: 300px;
                    margin: auto;
                    justify-content: space-between;
                    padding: 10px 0;
                }    
                label{
                    font-size: 15px;
                    color: gray;
                    font-weight: bold;
                }
                input{
                    font-size: 25px;
                    width: 200px;
                    box-sizing: border-box;
                    border: none;
                    background-color: #e0e0e0;
                }
            }
            #LoginSubmit{
                background-color: orange;
                border: 0;
                color: white;
                width: 400px;
                height: 50px;
                margin: 50px auto;
                &:hover{
                    background-color: orangered;
                }
            }
            li{
                list-style: none;
            }
            #SingupSubmit{
                background-color: orange;
                border: 0;
                color: white;
                width: 200px;
                height: 50px;
                margin: 50px auto;
                &:hover{
                    background-color: orangered;
                }
            }
        }
    }
    .MoveHome{
        position: absolute;
        top: 0;
        left: 0;
        background-color: orange;
        padding: 10px;
        color: white;
        text-decoration: none;
        margin: 10px;
        border-radius: 5px;
    }
    .MoveOAuth{
        position: absolute;
        top: 0;
        right: 0;
        background-color: orangered;
        padding: 10px;
        color: white;
        text-decoration: none;
        margin: 10px;
        border-radius: 5px;
    }
    .AuthInfo{
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    .TwitchLoginBox{
        background-color: #9146ff;
        width: 80%;
        height: 50px;
        margin: 10px auto;
        line-height: 50px;
        font-size: 30px;
        color: white;
        text-decoration: none;
        text-align: center;
    }
    .YoutubeLoginBox{
        background-color: red;
        width: 80%;
        height: 50px;
        margin: 10px auto;
        line-height: 50px;
        font-size: 30px;
        color: white;
        text-decoration: none;
        text-align: center;
    }
   
}


#LoginPage { 
    font-family: "프리텐다드SemiBold", sans-serif;
    background-color: #202020;
    height: 100vh;
    color: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    #PageMain{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        #logoBox{
            margin: 10px;
            width: 100px;
            height: 100px;
            background-color: orange;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 70px;
                height: 70px;
                // animation-duration: 3s;
                // animation-name: loginlogo_animation;
            }
            border-radius:999px;
            text-align: center;
            // animation-duration: 3s;
            // animation-name: login_animation;
      
        }
        #infoText{
            margin: 10px;
            font-size: 20px;
        }
        #GoogleOAuth{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 40px;
            margin: 10px;
            font-size: 20px;
            border-radius: 300px;
            color: #000000;
            background-color: #fff;
            #GoogleOAuthLogo{
                margin-right: 10px;
            }
            #GoogleOAuthText{
                
            }
        }
    }
    #HoverAlert{
        position: absolute;
        width: 100vw;
        height: 100vh;
        pointer-events: none;
        display: flex;
        justify-content: center;

        .AlertMsg{
            animation: login_AlertShake 0.5s ease-in-out 3;
            margin-top: 100px;
            position: absolute;
            display: flex;
            height: 30px;
            padding: 10px 20px;
            background-color: #444;
            border-radius: 30px;
            line-height: 30px;
        }
    }
    main{
        color: white;
        width: 1000px;
        height: 1000px;
        // border: 1px solid black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .MoveHome{
        position: absolute;
        display:flex;
        justify-content: center;
        align-items: center;
        top: 20px;
        left: 20px;
        background-color: #444;
        padding: 10px;
        color: white;
        text-decoration: none;
        margin: 10px;
        border-radius: 5px;
    }
   
}

@keyframes login_AlertShake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
}

// @media screen and (max-width: 800px) {
//     #LoginPage {
//         width: 100vw;
//         background-color: #444;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }
//     main{
//         display: flex;
//         width: 300px !important;
//         // height: 400px;
//         flex-direction: column;
//         align-items: flex-start !important;
//         #infoBox{
//             width: 300px !important;
//             display: flex;
//             justify-content: center;
//             flex-direction: column;
//             img{
//                 height: 200px;
//                 object-fit: scale-down;
//             }
//             h3{
//                 color: white;
//                 margin: 0px auto;
//             }
  
//         }
//     }
// }
// @media screen and (max-width: 300px) {

//     main{
//         display: flex !important;
//         // margin: auto;
//         width: 100% !important;
//         // height: 400px;
//         #infoBox{
//             width: 100% !important;
//             display: flex;
//             justify-content: center;
//             flex-direction: column;
//             img{
//                 height: 200px;
//                 object-fit: scale-down;
//             }
//             h3{
//                 display: none;
//                 color: white;
//                 margin: 0px auto;
//             }
  
//         }
//         #LoginBox{
//             width: 100% !important;
//             input{
//                 width: 100% !important;
//             }
//             #LoginSubmit{
//                 width: 70% !important;    
//                 margin : 50px 15% !important;  
//             }
//         }
//     }
// }



@keyframes login_animation {
    from {
      transform: scale(100);
    }
    to {
        transform: scale(1);
    }
  }

  @keyframes loginlogo_animation {
    from {
      transform: scale(3);
    }
    to {
        transform: scale(1);
    }
  }




#SingUpPage{
font-family: "프리텐다드SemiBold", sans-serif;
background-color: #202020;
height: 100vh;
color: white;
display: flex;
justify-content: center;
align-items: center;
    main{
        width: 1000px;
        height: 500px;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        #infoBox{
            width: 500px;
            height: 500px;
            background-color: orange;
            img{
                width: 300px;
                height: 300px;
                margin: 100px;
            }
            text-align: center;
            font-size: 30px;
        }
        #SingUpBox{
            width: 500px;
            height: 500px;
            background-color: whitesmoke;
            color: black;
            label{
                font-size: 15px;
                color: gray;
                font-weight: bold;
            }
            input{
                font-size: 25px;
                width: 250px;
                box-sizing: border-box;
                outline: none;
                border:  2px solid #22222250;
                border-radius: 5px;
                background-color: white;
            }
            #socialLoginBox{
                margin-top: 50px;
                #socialLoginBoxTitle{
                    height: 40px;
                    margin: 20px;
                    font-size: 30px;
                    text-align: center;
                    font-family: "프리텐다드SemiBold", sans-serif;
                }
                #socialLoginBoxInfo{
                    height: 50px;
                    margin: 20px;
                    font-size: 15px;
                    line-height: 25px;
                    text-align: center;
                }

                
                
            }
            #localSingupBox{
                #localSingup{
                    background-color: white;
                    margin: 10px 25px;
                    padding: 15px 0px;
                    height: 330px;
                    border-radius: 2.5px;
                    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    #SingupBoxInput{
                        list-style: none;
                        display: flex;
                        flex-direction: column;
                        padding: 0px 20px;
                        margin-bottom: 20px;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        li{
                            padding: 7.5px 5px; 
                            list-style: none;
                            display: flex;
                            justify-content: space-around;
                            p{
                                width: 100px;
                                height: 35px;
                                line-height: 35px;
                                text-align: right;
                                margin-right: 20px;
                            }
                            input{
                                background-color: #f3f3f3;
                                line-height: 29px;
                                border-radius: 2.5px;
                                border: none;
                                font-size: 18px;
                            }
                            input::placeholder{
                                line-height: 29px;
                            }
                        }
                    }
                    
                }
            }
            #SingUpSelectBox{
                margin: 25px 0px;
                .infoText{
                    margin : 30px;
                    font-size: 15px;
                }
                #socialLoginBoxList{
                    margin: 0px 25px;
                    border-radius: 999px;
                    display: flex;
                    background-color: white;
                    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    justify-content: center;
                    .loginMethod{
                        width: 50px;
                        height: 50px;
                        margin: 10px;
                        border-radius: 50px;
                        background-color: whitesmoke;
                        box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                        overflow: hidden;
                        transition: transform 0.15s;
                        img{
                            width: 35px;
                            height: 35px;
                            padding: 7.5px;
                        }
                    }
                    .loginMethod.google{
                        background-color: #F60102;
                    }
                    .loginMethod.twitch{
                        background-color: #64459B;
                    }
                    .loginMethod.naver{
                        background-color: #39C73D;
                    }
                    .loginMethod.kakao{
                        background-color: #FCE813
                    }
                    .loginMethod.facebook{
                        background-color: #3A589E;
                    }
                    .loginMethod:hover{
                        transform: scale(1.1);
                       
                    }
                }
                
            }
            
            .infoText{
                text-align: center;
      
                h3,h2{
                    margin: 20px;
                }
                h2{
                    // font-size: 30px;
                }
            }
            
            #localSingupSubmitContainer{
                height: 50px;
                margin: 0px 25px;
                display: flex;
                flex-direction: row-reverse;
                #localSingupSubmit{
                    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    height: 70px;
                    width: 100%;
                    font-size: 20px;
                    background-color: orange;
                    color: white;
                    border-radius: 999px;
                    border:none;
                    transition: box-shadow 0.2s;
                    display: flex ;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 40px;
                        margin-right: 20px;
                    }
                    &:hover{
                        background-color: orangered;
                        // box-shadow: 0 10px 15px rgba(0, 0, 0, 0), 0 5px 5px rgba(0, 0, 0, 0);
                    }
                }
            }
            #SingupButtonContainer{
                height: 50px;
                margin: 0px 25px;
                display: flex;
                flex-direction: row-reverse;
                #SingupSubmit{
                    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
                    height: 70px;
                    width: 100%;
                    font-size: 20px;
                    background-color: orange;
                    color: white;
                    border-radius: 2.5px;
                    border:none;
                    transition: box-shadow 0.2s;
                    display: flex ;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 40px;
                        margin-right: 20px;
                    }
                    &:hover{
                        background-color: orangered;
                        // box-shadow: 0 10px 15px rgba(0, 0, 0, 0), 0 5px 5px rgba(0, 0, 0, 0);
                    }
                }
            }
        }
    }   
}

#NewAccountSetting{
    font-family: "프리텐다드SemiBold", sans-serif;
    background-color: #202020;
    height: 100vh;
    width: 100vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    #userNameSetting{
        width: 100%;
        max-width: 500px;

        border: solid 1px orange;
        height: 500px;
        display: flex;
        flex-direction: column;
        .setUserName{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100px;
            align-items: center;
            p{
                font-size: 15px;
                color: white;
                height: 20px;
                padding: 10px 0px;
            }
            input{
                font-family: "프리텐다드Bold", sans-serif;
                background-color: #555;
                width: 200px;
                height: 30px;
                font-size: 20px;
                line-height: 30px;
                color:white;
                border-radius: 5px;
                text-align: center;
                border:none;
                outline: none;
            }
        }
        .setUserImg{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 200px;
            align-items: center;
            p{
                font-size: 15px;
                color: white;
                height: 20px;
                padding: 10px 0px;
            }
            .selectIMG{
                font-family: "프리텐다드Bold", sans-serif;
                background-color: orange;
                width: 100px;
                height: 100px;
                font-size: 20px;
                line-height: 30px;
                color:white;
                border-radius: 150px;
                text-align: center;
                border:none;
                outline: none;
            }
            .listIMG{
                margin: 10px;
                width: 40px;
                width: 40px;
                border-radius: 50px;
            }
        }
    }
    .setTitle{
        width: 500px;
        height: 50px;
        padding: 0px;
        margin: 25px 0px ;
        color :white;
        text-align: center;
        font-size: 40px;
        font-family: "프리텐다드Bold", sans-serif;
    }
    .setField{
        height: calc(100% - 180px);
    }
    .AccountSettingButtons{
        display: flex;
        height: 80px ;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 20px;
        line-height: 50px;
        justify-content: space-between;

        .buttonDiv{
            width: 100px;
            height: 40px;
        }

        button{
            width: 100px;
            height: 40px;
            border-radius: 5px;
            border :none;
            font-size: 20px;
            color: white;
            font-family: "프리텐다드Bold", sans-serif;
        }

        button.prev{
            background-color: black;
        }
        button.next{
            background-color: orange;
        }
    
    }
  
}
