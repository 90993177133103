.ScoreBoard{

}

.ScoreBoard{
    /////////////////////////////////////////
    // 기본 스코어보드
    /////////////////////////////////////////
    &.defaultScoreBoard{
        display: flex;
        // width:1920px;
        // height: 100px;
        justify-content: center;
        align-items: start;
        height: 100%;
        width: 100%;
        .teamInfo{
            display: flex;
            position: absolute;
            width: 578px;
            height: 100%;
            line-height: 100%;
            top: 0px;
            justify-content: flex-end;
        }
        .matchInfo{
            display:inline-flex;
            padding: 0px 20px;
            min-height: 0px;
            max-height: 50px;
            overflow: hidden;
            background: linear-gradient(to right, rgba(0, 0, 0, .0), rgba(0, 0, 0, .4), rgba(0, 0, 0, .5), rgba(0, 0, 0, .4), rgba(0, 0, 0, .0));
        }
        .teamLogo{
            display: flex;

            height: 50px;
            width: 50px;
            justify-content: center;
            align-items: center;
            background-color: rgba($color: #000000, $alpha: 0.5);
        }
        .teamLogo img{
            display: flex;
            height: 45px;
            width: 45px;
            object-fit: scale-down;
            border-radius: 10px;
        }
        .teamName{
            padding: 0px 10px ;
            box-sizing: content-box;
            font-size: 40px;
            font-family: '프리텐다드SemiBold';
            overflow: hidden;
            line-height: 50px;
            height: 50px;
            flex: 1;
        }
        .teamScroe{
            display: flex;
            background-color: white;
            height: 50px;
            width: 50px;
            color: black;
            justify-content: center;
            align-items: center;
            font-size: 35px;
            font-family: '프리텐다드SemiBold';
        }
        .leftTeam{
            display: flex;
            position: absolute;
            align-items: center;

            left: 40px;
            height:50px;
            .teamName{
                text-align: right;
            }
        }
        .rightTeam{
            display: flex;
            flex-direction: row-reverse;
            position: absolute;
            align-items: center;
            right: 40px;
            height:50px;
            .teamName{
                text-align: left;
            }
        }
        .designLine{

        }
    }
    /////////////////////////////////////////
    // 틸트 스코어보드
    /////////////////////////////////////////
    &.tiltedScoreBoard{
        display: flex;
        // width:1920px;
        // height: 100px;
        justify-content: center;
        align-items: start;
        height: 100%;
        width: 100%;
        .teamInfo{
            display: flex;
            position: absolute;
            width: 630px;
            height: 100%;
            line-height: 100%;
            top: 0px;

            
            justify-content: flex-end;
        }
        .matchInfo{
            display:inline-flex;
            padding: 0px 20px;
            min-height: 0px;
            max-height: 50px;
            overflow: hidden;
            background: linear-gradient(to right, rgba(0, 0, 0, .0), rgba(0, 0, 0, .4), rgba(0, 0, 0, .5), rgba(0, 0, 0, .4), rgba(0, 0, 0, .0));
        }
        .teamLogo{
            display: flex;

            height: 50px;
            width: 200px;
            justify-content: center;
            align-items: center;

        }
        .teamLogo img{
            display: flex;
            height: 50px;
            width: 200px;
            object-fit: cover;
        }
        .teamName{
            padding: 0px 10px ;
            box-sizing: content-box;
            font-size: 40px;
            font-family: '프리텐다드SemiBold';
            overflow: hidden;
            line-height: 50px;
            height: 50px;
            flex: 1;
        }
        .teamScroe{
            display: flex;
            background-color: white;
            height: 50px;
            width: 150px;
            color: black;
            justify-content: center;
            align-items: center;
            font-size: 40px;
            font-family: '프리텐다드SemiBold';
        }
        .leftTeam{
            display: flex;
            position: absolute;
            align-items: center;
            left:0px;
            height:50px;
            clip-path: polygon(50px 0px, 100% 0%, calc(100% - 50px) 100%, 0% 100%);
            .teamLogo{
                clip-path: polygon(50px 0px, 100% 0%, calc(100% - 50px) 100%, 0% 100%);
                transform: translateX(-100px);
                position: absolute;
            }
            .teamScroe{
                clip-path: polygon(50px 0px, 100% 0%, calc(100% - 50px) 100%, 0% 100%);
                position: absolute;

            }
            .teamName{
                width: 100%;
                padding-right: 80px;
                transform: translateX(-250px);
                text-align: right;
                clip-path: polygon(50px 0px, 100% 0%, calc(100% - 50px) 100%, 0% 100%);
                position: absolute;
            }
        }
        .rightTeam{
            display: flex;
            flex-direction: row-reverse;
            position: absolute;
            align-items: center;
            right:0px;
            height:50px;
            clip-path: polygon(0% 0%,  calc(100% - 50px) 0%, 100% 100%, 50px 100%);
            .teamLogo{
                clip-path: polygon(0% 0%,  calc(100% - 50px) 0%, 100% 100%, 50px 100%);
                transform: translateX(100px);
                position: absolute;
            }
            .teamScroe{
                clip-path: polygon(0% 0%,  calc(100% - 50px) 0%, 100% 100%, 50px 100%);
                position: absolute;
            }
            .teamName{
                width: 100%;
                transform: translateX(250px);
                padding-left: 80px;
                text-align: left;
                position: absolute;
                clip-path: polygon(0% 0%,  calc(100% - 50px) 0%, 100% 100%, 50px 100%);
            }
        }
        .designLine{

        }
    }
    /////////////////////////////////////////
    // 박스 스코어보드
    /////////////////////////////////////////
    &.boxStyleNScoreBoard{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .matchInfo{
            width: 100%;
         
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .teamArea{
            width: 100%;
            background-color: white;
        }
        .teamInfo{
            display: flex;
            .teamLogo{
                display: flex;
                width: 100%;
                height: 100%;
            }

        }
        .teamName{
            font-size: 20px;
            flex: 1;
            span{
                margin: 0px 10px;
            }
        }
        .teamScroe{
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
        }
    }
    /////////////////////////////////////////
    // 틸트 스코어보드
    /////////////////////////////////////////
    &.boxScoreBoard{
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height: 100%;
        .matchInfo{
            width: 100%;
            height: 50px;
        }
        .teamLogo{
            height: 100%;
            img{
                margin: 10%;
                width: 80%;
                height: 80%;
                border-radius: 10px;
            }
        }   
        .teamArea {
            display: flex;
            flex-direction: column;
            width: 100%;
            .TeamLine{
                width: 100%;
                height: 2px;  
                background-color: #aaa;
            }
        }
        .square {
            height: 100%; /* 부모 컨테이너의 50% 크기 */
            aspect-ratio: 1 / 1; /* 너비와 높이가 같은 비율 */
            // background-color: green;
        }
        .teamData{
            display: flex;
            width: 80%;
        }

        .teamInfo{
            display: flex;
            align-items: center;
            width: 100%;

        }
        .teamName{
            display: flex;
            align-items: center;
            color: black;
            overflow: hidden;

        }
        .teamScroe{            
            display: flex;
            justify-content: end;
            width: calc(20% - 20px);
            align-items: right;
            color: black;
            margin-right: 20px;
        }
        .leftTeam{

        }
        .rightTeam{


        }
    }

    /////////////////////////////////////////
    // 박스 스코어보드
    /////////////////////////////////////////
    &.sportsScoreBoard{
        overflow: hidden;

        .ScoreBox{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .teamInfo{
                width: 100%;
                display: flex;
                
            }
            .leftTeam{
                justify-content: end;
                align-items: center;
            }
            .rightTeam{
                justify-content:start;
                align-items: center;
            }
            .teamName{
                flex: 1;
                overflow: hidden;
            }
            .teamScroe{
                background-color: #fff;
                color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .LeftName{
                box-sizing: border-box;
                padding: 0px 10px;
                text-align: right;
            }
            .RightName{
                box-sizing: border-box;
                padding: 0px 10px;
                text-align: left;
            }
        }
    }

}



// .overwatch_normal{

//     $size : 40px;
  
//     display: flex;
//     // width:1920px;
//     // height: 100px;
//     justify-content: center;
//     .teamInfo{
//         display: flex;
//         position:absolute;
//         width: 36%;
//         height: $size;
//         line-height: $size;
//         top:20px;
//         justify-content: flex-end;
//     }
//     .matchInfo{
//         width: 200px;
//         height: 38px;
//         position: absolute;
//         top: 10px;
//         left: 860px;
//         background-color: #444;
//         text-align: center;
//         color: #fff;
//         animation: moveMid 5s;
//         border-bottom: 2px solid orange;
//         margin: 0;
//     }
//     .teamName{
//         padding: 0px 20px;
//     }
//     .teamScroe{
//         width: $size;
//         height: $size;
//         color: #fff;
//         text-align: center;
//         background-color: #444;
//     }
//     .leftTeam{
//         display: flex;
//         position: absolute;
//         width: 36%;
//         height: 40px;
//         line-height: 40px;
//         top: 20px;
//         justify-content: flex-end;
//         left: 0px;
//         font-size: 30px;
//         font-family: 나눔스퀘어;
//         background-color: rgb(247, 115, 151);
//         flex-direction: row;
//         text-align: right;
//         color: rgb(255, 255, 255);
//         animation: 5s ease 0s 1 normal none running moveLeft;
//     }
//     .rightTeam{
//         display: flex;
//         position: absolute;
//         width: 36%;
//         height: 40px;
//         line-height: 40px;
//         top: 20px;
//         justify-content: flex-end;
//         right: 0px;
//         font-size: 30px;
//         font-family: 나눔스퀘어;
//         background-color: rgb(89, 203, 232);
//         flex-direction: row-reverse;
//         text-align: left;
//         color: rgb(255, 255, 255);
//         animation: 5s ease 0s 1 normal none running moveRight;
//     }
//     .designLine{
//         background-color: orange;
//         width: 5px;
//         height: $size;
//     }
// }

// .overwatch_new{

//     $size : 40px;
//     display: flex;
//     width:1920px;
//     height: 100px;
// }

// .small { font: italic 13px sans-serif; }
// .heavy { font: bold 30px sans-serif; }



// .overwatch_underbar{
//     $size : 40px;
//     display: flex;
//     justify-content: center;
//     .playing{
//         // display: none;
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         // transform: translateY(50%);
//         // background-color: #222;
//         clip-path: polygon(0% 0%, 4% 0%, 6% 25%, 6% 50%, 94% 50%, 94% 25%, 96% 0%, 100% 0%, 100% 100%, 0% 100%);
//         overflow: hidden;
//         animation: 1s ease 0s 1 normal forwards running overwatch_underbar;
//         .matchHeader{
//             width: 100%;
//             height: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             .matchBox{

//                 display: flex;
//                 width: 100%;
//                 height: 100%;
//                 justify-content: space-between;
//                 align-items: center;

//                 .teamInfo{
//                     display: flex;
//                     // flex-direction: row-reverse;
//                     width: 40%;
//                     height: 100%;
//                     color: white;
//                     font-size: 35px !important;
//                     line-height: 54px;
//                     .teamScroe{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 16%;
//                         background-color: #0f1822;
//                     }
//                     .teamName{
//                         // margin-top: 5%;
//                         height: 100%;
//                         display: flex;
//                         // justify-content: center;
//                         // align-items: center;
//                         width: 85%;
//                         padding: 0% 2.5% ;
//                         transform: translateY(50%);
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row-reverse;
//                     // animation: 1s ease 0s 1 normal forwards running overwatch2_simplePlayingLeftTeam;
//                     // clip-path: polygon(0% 0%, 100% 0%, 96% 100%, 0% 100%, 0% 0%, 0% 0%);
//                     .teamScroe{
//                         // clip-path: polygon(75% 0%, 100% 0%, 75% 100%, 0% 100%, 25% 0%, 0% 0%);
//                     }
//                     .teamName{
//                         justify-content: flex-start;
//                     }
//                 }
//                 .rightTeam{
//                     flex-direction: row;
//                     // animation: 1s ease 0s 1 normal forwards running overwatch2_simplePlayingRightTeam;
//                     // clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 4% 100%, 0% 0%, 0% 0%);
//                     .teamScroe{
//                         // clip-path: polygon(100% 0%, 75% 0%, 100% 100%, 25% 100%, 0% 0%, 25% 0%);
//                     }
//                     .teamName{
//                         justify-content: flex-end;
//                     }
//                 }
//                 .matchInfo{
//                     display: flex;
//                     width: 20%;
//                     height: 100%;
//                     .matchText{
//                         justify-content: center;
//                         text-align: center;
//                         display: flex;
//                         width: 100%;
//                         height: 100%;
//                         transform: translateY(50%);
//                         color: white;
//                     }
//                     // background: linear-gradient(to right, #70e1f5, #ffd194); 
//                 }
//             }
//         }
//     }
//     @keyframes overwatch_underbar {
//         0% {  opacity: 0; transform: translateY(100%); }
//         100% { opacity: 1;  transform: translateY(0%); }
//     } 

// }

// .overwatch2_normal{
//     $size : 40px;
//     display: flex;
//     justify-content: center;
//     .opening{
//         position: absolute;
//         transform: translateY(117.5%);
//         // border:red 1px solid;
//         width: 100%;
//         height: 300%;
//         // background-color: red;
//         animation: 1s ease 5s 1 normal forwards running overwatch2_normalOpening;
//         .matchBox{
//             width: 40%;
//             height: 100%;
//             background-color: rgb(15, 24, 34);
//             margin: auto;
//             display: flex;
//             flex-direction: column;
//             // align-items: center;
//             animation: 1s ease 0s 1 normal none running overwatch2_normalOpeningMatchBox;
//             .matchHeader{
//                 height: 30%;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 .matchInfo{
//                     color: white;
//                     text-align: center;
//                     height: 50%;
//                     font-size: 30px !important;
//                 }
//             }
//             .matchTeam{
//                 height: 40%;
//                 .teamInfo{
//                     position: absolute;
//                     width: 40%;
//                     height: 40%;
//                     display: flex;
//                     align-items: center;
//                     .teamScroe{
//                         color: black;
//                         font-size: 60px;
//                         width: 13.5%;
//                         height: 80%;
//                         margin: 0px 15px;
//                         background-color: white;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                     }
//                     .teamName{
//                         color: white;
//                         width: 70%;
//                         font-size: 50px;
//                         overflow: hidden;
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row;
//                     transform: translateX(-60%);
//                     text-align: left;
//                     animation: 1s ease 0s 1 normal none running overwatch2_normalOpeningLeftTeam;
//                 }
//                 .rightTeam{
//                     flex-direction: row-reverse;
//                     transform: translateX(60%);
//                     text-align: right;
//                     animation: 1s ease 0s 1 normal none running overwatch2_normalOpeningRightTeam;
//                 }
//             }
            
//             .matchInfo{
//                 height: 30%;
//             }
            
//         }
        
//     }
//     .playing{
//         // display: none;
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         transform: translateY(10%);
//         // opacity: 0;
//         // background-color: rgb(247, 115, 151);
  
//         .matchHeader{
//             width: 100%;
//             height: 50%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             // background-color: rgb(247, 115, 151);
            
//             .matchBox{
//                 opacity: 0; 
//                 animation: 1s ease 6s 1 normal forwards running overwatch2_normalPlaying;
//                 display: flex;
//                 width: 100%;
//                 height: 100%;
//                 // clip-path: polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%);
//                 justify-content: space-between;
//                 align-items: center;

//                 .teamInfo{
//                     display: flex;
//                     // flex-direction: row-reverse;
//                     width: 34.5%;
//                     height: 100%;
//                     color: white;
//                     font-size: 35px !important;
//                     line-height: 54px;
//                     .teamScroe{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 15%;
//                         background-color: #0f1822;

//                     }
//                     .teamName{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 85%;
//                         padding: 0% 2.5% ;
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row;
//                     animation: 1s ease 6s 1 normal both running overwatch2_normalPlayingLeftTeam;
//                     clip-path: polygon(0% 0%, 100% 0%, 96% 100%, 0% 100%, 0% 0%, 0% 0%);
//                     .teamScroe{
//                         clip-path: polygon(75% 0%, 100% 0%, 75% 100%, 0% 100%, 25% 0%, 0% 0%);
//                     }
//                     .teamName{
//                         justify-content: flex-end;
//                     }
//                 }
//                 .rightTeam{
//                     flex-direction: row-reverse;
//                     animation: 1s ease 6s 1 normal both running overwatch2_normalPlayingRightTeam;
//                     clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 4% 100%, 0% 0%, 0% 0%);
//                     .teamScroe{
//                         clip-path: polygon(100% 0%, 75% 0%, 100% 100%, 25% 100%, 0% 0%, 25% 0%);
//                     }
//                     .teamName{
//                         justify-content: flex-start;
//                     }
//                 }
//             }
//         }

//     }
//     @keyframes overwatch2_normalOpeningMatchBox {
//         0% { opacity: 0; }
//         10% { opacity: 0; }
//         15% { opacity: 0; }
//         100% { opacity: 1; }
//     } 
//     @keyframes overwatch2_normalOpeningLeftTeam {

//         0% { transform: translateX(-260%); }
//         100% { transform: translateX(-60%); }

//     } 
//     @keyframes overwatch2_normalOpeningRightTeam {
//         0% { transform: translateX(260%); }
//         100% { transform: translateX(60%); }
//     } 

//     @keyframes overwatch2_normalOpening {
//         0% { opacity: 1; }
//         99% { opacity: 0; display: none; width: 100%; height: 300%; }
//         100% { opacity: 0; display: none; width: 0px; height: 0px;}
//     } 
//     @keyframes overwatch2_normalPlaying {
//         0% { opacity: 1; }
//         100% { opacity: 1; }
        
//     } 

//     @keyframes overwatch2_normalPlayingLeftTeam {

//         0% {  opacity: 1; transform: translateX(-100%); }
//         100% { opacity: 1;  transform: translateX(0%); }

//     } 
//     @keyframes overwatch2_normalPlayingRightTeam {
//         0% {  opacity: 1; transform: translateX(100%); }
//         100% { opacity: 1;  transform: translateX(0%); }
//     } 

// }


// .overwatch2_simple{
//     $size : 40px;
//     display: flex;
//     justify-content: center;
//     .playing{
//         // display: none;
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         transform: translateY(10%);
//         // opacity: 0;
//         // background-color: rgb(247, 115, 151);
  
//         .matchHeader{
//             width: 100%;
//             height: 50%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             // background-color: rgb(247, 115, 151);
            
//             .matchBox{
//                 animation: 1s ease 6s 1 normal forwards running overwatch2_normalPlaying;
//                 display: flex;
//                 width: 100%;
//                 height: 100%;
//                 // clip-path: polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%);
//                 justify-content: space-between;
//                 align-items: center;

//                 .teamInfo{
//                     display: flex;
//                     // flex-direction: row-reverse;
//                     width: 32.8%;
//                     height: 100%;
//                     color: white;
//                     font-size: 35px !important;
//                     line-height: 54px;
//                     .teamScroe{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 10%;
//                         background-color: #0f1822;
//                     }
//                     .teamName{
//                         display: flex; 
//                         justify-content: center;
//                         align-items: center;
//                         width: 85%;
//                         padding: 0% 2.5% ;
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row;
//                     animation: 1s ease 0s 1 normal forwards running overwatch2_simplePlayingLeftTeam;
//                     // clip-path: polygon(0% 0%, 100% 0%, 96% 100%, 0% 100%, 0% 0%, 0% 0%);
//                     .teamScroe{
//                         // clip-path: polygon(75% 0%, 100% 0%, 75% 100%, 0% 100%, 25% 0%, 0% 0%);
//                     }
//                     .teamName{
//                         justify-content: flex-end;
//                     }
//                 }
//                 .rightTeam{
//                     flex-direction: row-reverse;
//                     animation: 1s ease 0s 1 normal forwards running overwatch2_simplePlayingRightTeam;
//                     // clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 4% 100%, 0% 0%, 0% 0%);
//                     .teamScroe{
//                         // clip-path: polygon(100% 0%, 75% 0%, 100% 100%, 25% 100%, 0% 0%, 25% 0%);
//                     }
//                     .teamName{
//                         justify-content: flex-start;
//                     }
//                 }
//             }
//         }
//     }
//     @keyframes overwatch2_simplePlayingLeftTeam {
//         0% {  opacity: 1; transform: translateX(-100%); }
//         100% { opacity: 1;  transform: translateX(0%); }
//     } 
//     @keyframes overwatch2_simplePlayingRightTeam {
//         0% {  opacity: 1; transform: translateX(100%); }
//         100% { opacity: 1;  transform: translateX(0%); }
//     } 

// }

// .overwatch2_header{
//     $size : 40px;
//     display: flex;
//     justify-content: center;

//     .playing{
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         .matchInfoText{
//             position: absolute;
//             top: 200%;
//             width: 100%;
//             height: 100%;
//             display: flex;
//             justify-content: center;
   
//             animation: 5s ease 0s 1 normal forwards running overwatch2_headerMatchInfo;
//             .matchInfo{
//                 width: 20%;
//                 height: 80%;
//                 overflow: hidden;
//                 background-color: #0f1822;
//                 border-bottom: 5px solid orange;
//                 // box-shadow: 0px 5px 10px black;
//                 color: white;
//                 text-align: center;
//                 font-size: 25px !important;
//             }
//         }
//         .matchHeader{
//             width: 100%;
//             height: 150%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             animation: 5s ease 0s 1 normal forwards running overwatch2_headerMatchHeader;
//             .matchBox{
//                 opacity: 0; 
//                 animation: 1s ease 0s 1 normal forwards running overwatch2_headerPlaying;
//                 display: flex;
//                 width: 100%;
//                 height: 100%;
//                 justify-content: space-between;
//                 align-items: center;

//                 .teamInfo{
//                     display: flex;
//                     width: 50%;
//                     height: 100%;
//                     color: white;
//                     font-size: 35px !important;
//                     line-height: 54px;
//                     .teamScroe{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 16.5%;
//                         background-color: #0f1822;
//                         animation: 5s ease 0s 1 normal forwards running overwatch2_headerPlayingScroe;
//                     }
//                     .teamName{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 83.5%;
//                         height: 100%;
//                         padding: 0% 2.5% ;
//                         animation: 5s ease 0s 1 normal forwards running overwatch2_headerPlayingTeam;
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row;
//                     // box-shadow: 5px 5px 5px black;
//                     animation: 5s ease 0s 1 normal forwards running overwatch2_headerPlayingLeftTeam;
//                     .teamScroe{
//                         // box-shadow: 5px 5px 5px black;
//                     }
//                     .teamName{
//                         justify-content: flex-end;
//                     }
//                 }
//                 .rightTeam{
//                     flex-direction: row-reverse;
//                     // box-shadow: 5px 5px 5px black;
//                     animation: 5s ease 0s 1 normal forwards running overwatch2_headerPlayingRightTeam;
//                     .teamScroe{
//                         // box-shadow: -5px 5px 5px black;
//                     }
//                     .teamName{
//                         justify-content: flex-start;
//                     }
//                 }
//             }
//         }

//     }
    
//     @keyframes overwatch2_headerPlaying {
//         0% { opacity: 1; }
//         100% { opacity: 1; }
//     } 

//     @keyframes overwatch2_headerPlayingLeftTeam {

//         0% {  opacity: 1; transform: translateX(-100%); }
//         10% { opacity: 1;  transform: translateX(0%); width: 50%;}
//         40% { opacity: 1;  transform: translateX(0%); width: 50%;}
//         60% { opacity: 1;  width: 35%;}
//         100% { opacity: 1;  transform: translateX(0%); width: 35%;
//             // clip-path: polygon(0% 0%, 0% 100%, 6.5% 100%, 6.5% 65%, 84% 65%, 84% 100%, 100% 100%, 100% 0%);
//         }

//     } 
//     @keyframes overwatch2_headerPlayingRightTeam {
//         0% {  opacity: 1; transform: translateX(100%); }
//         10% { opacity: 1;  transform: translateX(0%); width: 50%;}
//         40% { opacity: 1;  transform: translateX(0%); width: 50%;}
//         60% { opacity: 1;   width: 35%;}
//         100% { opacity: 1;  transform: translateX(0%); width: 35%; 
//             // clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 2% 66%, 80% 65%, 80% 100%, 100% 100%, 100% 0%);
//         }
//     } 

//     @keyframes overwatch2_headerPlayingScroe {
//         60% { height: 100%;}
//         70% { height: 160%;}
//         100% { height: 160%;}
//     } 
//     @keyframes overwatch2_headerMatchHeader {
//         60% { height: 150%;}
//         70% { height: 66%;}
//         100% { height: 66%;}
//     } 

//     @keyframes overwatch2_headerMatchInfo {
//         0% { opacity: 1; top: -100%; }
//         10% { opacity: 1; top: 200%; }
//         60% { opacity: 1; top: 200%; }
//         70% { opacity: 1; top: 0%; }
//         90% { opacity: 1; top: 0%; }
//         99% { opacity: 1; top: -100%; }
//         100% { opacity: 0; top: -100%; }
//     } 
    
    
// }



// .Scoreboard_preset_LeagueofLegends_normal{
//     display: flex;
//     .teamInfo{
//         display: flex;
//         position:absolute;
//         width: 10.4%;
//         height: 40%;
//         justify-content: flex-end;
//         background-color: red;
//     }
//     .teamScroe{

//     }
//     .teamName{
     
//     }
//     // .leftTeam{
//     //     left: 20%;
//     //     justify-content: flex-start;
//     //     flex-direction: row-reverse;
//     // }
//     // .rightTeam{
//     //     justify-content: flex-start;
//     //     flex-direction: row;
//     //     right: 20%;
//     // }
// }
// .Scoreboard_preset_LeagueofLegends_GameStyle{
//     display: flex;
//     .ScoreBoard{
//         // animation: 1s linear 0s 1 normal none running popup;
//     }
//     .teamInfo{
//         display: flex;
//         position:absolute;
//         width: 10.4%;
//         height: 40%;
//         justify-content: flex-end;
//         background-color: red;
//     }
//     .teamScroe{
//         text-shadow: 0px 0px 5px #000;
//     }
//     .teamName{
//         text-shadow: 0px 0px 5px #000;
//     }
//     .leftTeam{
//         animation: 1s ease 0s 1 normal none running popup;
//     }
//     .rightTeam{
//         animation: 1s ease 0s 1 normal none running popup;
//     }
// }

// @keyframes hideshow {
//     0% { opacity: 0; }
//     10% { opacity: 0; }
//     15% { opacity: 0; }
//     100% { opacity: 1; }
//   } 
//   @keyframes popup {
//     0% {
//       transform: translateY(-100%);
//     }
//     100% {
//         transform: translateY(0%);
//     }
//   }



// .valorant_normal{
//     $size : 40px;
//     display: flex;
//     justify-content: center;
//     .opening{
//         position: absolute;
//         transform: translateY(117.5%);
//         // border:red 1px solid;
//         width: 100%;
//         height: 300%;
//         // background-color: red;
//         animation: 1s ease 5s 1 normal forwards running valorant_normalOpening;
//         .matchBox{
//             width: 40%;
//             height: 100%;
//             background-color: rgb(15, 24, 34);
//             margin: auto;
//             display: flex;
//             flex-direction: column;
//             // align-items: center;
//             animation: 1s ease 0s 1 normal none running valorant_normalOpeningMatchBox;
//             .matchHeader{
//                 height: 30%;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 .matchInfo{
//                     color: white;
//                     text-align: center;
//                     height: 50%;
//                     font-size: 30px !important;
//                 }
//             }
//             .matchTeam{
//                 height: 40%;
//                 .teamInfo{
//                     position: absolute;
//                     width: 40%;
//                     height: 40%;
//                     display: flex;
//                     align-items: center;
//                     .teamScroe{
//                         color: black;
//                         font-size: 60px;
//                         width: 13.5%;
//                         height: 80%;
//                         margin: 0px 15px;
//                         background-color: white;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                     }
//                     .teamName{
//                         color: white;
//                         width: 70%;
//                         font-size: 50px;
//                         overflow: hidden;
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row;
//                     transform: translateX(-60%);
//                     text-align: left;
//                     animation: 1s ease 0s 1 normal none running valorant_normalOpeningLeftTeam;
//                 }
//                 .rightTeam{
//                     flex-direction: row-reverse;
//                     transform: translateX(60%);
//                     text-align: right;
//                     animation: 1s ease 0s 1 normal none running valorant_normalOpeningRightTeam;
//                 }
//             }
            
//             .matchInfo{
//                 height: 30%;
//             }
            
//         }
        
//     }
//     .playing{
//         // display: none;
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         // opacity: 0;
//         // background-color: rgb(247, 115, 151);
  
//         .matchHeader{
//             width: 100%;
//             height: 50%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             // background-color: rgb(247, 115, 151);
//             transform: translateY(150%);
//             .matchBox{
//                 opacity: 0; 
//                 animation: 1s ease 6s 1 normal forwards running valorant_normalPlaying;
//                 display: flex;
//                 width: 22%;
//                 height: 100%;
//                 // clip-path: polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%);
//                 justify-content: space-between;
//                 align-items: center;
//                 background-color: #0f1822;
//                 .teamInfo{
//                     display: flex;
//                     width: 50%;
//                     height: 100%;
//                     // align-items: center;
//                     // padding: 10px;
//                     color: white;
//                     // box-sizing: border-box;
//                     font-size: 20px !important;
//                     .teamScroe{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 30%;
//                         background-color: #0f1822;

//                     }
//                     .teamName{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 80%;
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row;
//                     .teamScroe{
//                         clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
//                     }
//                 }
//                 .rightTeam{
//                     flex-direction: row-reverse;
//                     .teamScroe{
//                         clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
//                     }
//                 }
//             }
//         }

//     }
//     @keyframes valorant_normalOpeningMatchBox {
//         0% { opacity: 0; }
//         10% { opacity: 0; }
//         15% { opacity: 0; }
//         100% { opacity: 1; }
//     } 
//     @keyframes valorant_normalOpeningLeftTeam {

//         0% { transform: translateX(-260%); }
//         100% { transform: translateX(-60%); }

//     } 
//     @keyframes valorant_normalOpeningRightTeam {
//         0% { transform: translateX(260%); }
//         100% { transform: translateX(60%); }
//     } 

//     @keyframes valorant_normalOpening {
//         0% { opacity: 1; }
//         99% { opacity: 0; display: none; width: 100%; height: 300%; }
//         100% { opacity: 0; display: none; width: 0px; height: 0px;}
//     } 
//     @keyframes valorant_normalPlaying {
//         0% { opacity: 1; clip-path: polygon(50% 0%, 50% 0%, 50% 50%, 50% 100%, 50% 100%, 50% 50%);}
//         100% { opacity: 1; clip-path: polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%);}
//     } 
// }


// .valorant_double{
//     $size : 40px;
//     display: flex;
//     justify-content: center;
//     .opening{
//         position: absolute;
//         transform: translateY(117.5%);
//         // border:red 1px solid;
//         width: 100%;
//         height: 300%;
//         // background-color: red;
//         animation: 1s ease 5s 1 normal forwards running valorant_normalOpening;
//         .matchBox{
//             width: 40%;
//             height: 100%;
//             background-color: rgb(15, 24, 34);
//             margin: auto;
//             display: flex;
//             flex-direction: column;
//             // align-items: center;
//             animation: 1s ease 0s 1 normal none running valorant_normalOpeningMatchBox;
//             .matchHeader{
//                 height: 30%;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 .matchInfo{
//                     color: white;
//                     text-align: center;
//                     height: 50%;
//                     font-size: 30px !important;
//                 }
//             }
//             .matchTeam{
//                 height: 40%;
//                 .teamInfo{
//                     position: absolute;
//                     width: 40%;
//                     height: 40%;
//                     display: flex;
//                     align-items: center;
//                     .teamScroe{
//                         color: black;
//                         font-size: 60px;
//                         width: 13.5%;
//                         height: 80%;
//                         margin: 0px 15px;
//                         background-color: white;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                     }
//                     .teamName{
//                         color: white;
//                         width: 70%;
//                         font-size: 50px;
//                         overflow: hidden;
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row;
//                     transform: translateX(-60%);
//                     text-align: left;
//                     animation: 1s ease 0s 1 normal none running valorant_normalOpeningLeftTeam;
//                 }
//                 .rightTeam{
//                     flex-direction: row-reverse;
//                     transform: translateX(60%);
//                     text-align: right;
//                     animation: 1s ease 0s 1 normal none running valorant_normalOpeningRightTeam;
//                 }
//             }
            
//             .matchInfo{
//                 height: 30%;
//             }
            
//         }
        
//     }
//     .playing{
//         // display: none;
//         position: absolute;
//         width: 100%;

//         height: 100%;
//         // opacity: 0;
//         // background-color: rgb(247, 115, 151);
  
//         .matchHeader{
//             width: 100%;
//             height: 50%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             // background-color: rgb(247, 115, 151);
//             transform: translateY(150%);
//             .matchBox{
//                 opacity: 0; 
//                 animation: 1s ease 6s 1 normal forwards running valorant_normalPlaying;
//                 display: flex;
//                 width: 44%;
//                 height: 100%;
//                 // clip-path: polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%);
//                 justify-content: space-between;
//                 align-items: center;
//                 background-color: #0f1822;
//                 .teamInfo{
//                     display: flex;
//                     width: 50%;
//                     height: 100%;
//                     // align-items: center;
//                     // padding: 10px;
//                     color: white;
//                     // box-sizing: border-box;
//                     font-size: 20px !important;
//                     .teamScroe{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 30%;
//                         background-color: #0f1822;
//                     }
//                     .teamSubScroe{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 20%;
//                         color: black;
//                         background-color: rgb(255, 255, 255);
//                     }
//                     .teamName{
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         width: 80%;
//                     }
//                 }
//                 .leftTeam{
//                     flex-direction: row;
//                     .teamScroe{
//                         clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
//                     }
//                     .teamSubScroe{
//                         border-right: 3px solid #0f1822;
//                     }
//                 }
//                 .rightTeam{
//                     flex-direction: row-reverse;
//                     .teamScroe{
//                         clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
//                     }
//                     .teamSubScroe{
//                         border-left: 3px solid #0f1822;
//                     }
//                 }
//             }
//         }

//     }
//     @keyframes valorant_normalOpeningMatchBox {
//         0% { opacity: 0; }
//         10% { opacity: 0; }
//         15% { opacity: 0; }
//         100% { opacity: 1; }
//     } 
//     @keyframes valorant_normalOpeningLeftTeam {

//         0% { transform: translateX(-260%); }
//         100% { transform: translateX(-60%); }

//     } 
//     @keyframes valorant_normalOpeningRightTeam {
//         0% { transform: translateX(260%); }
//         100% { transform: translateX(60%); }
//     } 

//     @keyframes valorant_normalOpening {
//         0% { opacity: 1; }
//         99% { opacity: 0; display: none; width: 100%; height: 300%; }
//         100% { opacity: 0; display: none; width: 0px; height: 0px;}
//     } 
//     @keyframes valorant_normalPlaying {
//         0% { opacity: 1; clip-path: polygon(50% 0%, 50% 0%, 50% 50%, 50% 100%, 50% 100%, 50% 50%);}
//         100% { opacity: 1; clip-path: polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%);}
//     } 
// }


