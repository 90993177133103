@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');


@mixin fontSmoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  }

#EditPage{
    @include fontSmoothing;
    display: flex;
    background-color: #666;
    // font-family: '프리텐다드Regular';
    font-family: '프리텐다드SemiBold';
    color: #fff;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    
    #EditSide{
        width: 20%;
        background-color: #444;
        height: 100vh;
        display: flex;
        flex-direction: column;
        nav{
            position: relative;
            #EditNavClose{
                width: 100%;
                height: 40px;
                .NavTitle{
                    width: 90%;
                    padding: 0 5%;
                    height: 40px;
                    background-color: orange;
                    display: flex;
                    justify-content: space-between;
        
                    img{
                        padding: 10px 0;
                        width: 20px;
                        height: 20px;
                    }
                    img:hover{
                        filter: opacity(50%);
                    }
                }
            }
            #EditNavOpen{
                width: 100%;
                height: 100vh;
                position: absolute;
                z-index: 99999;
                background-color: #222;
                // box-shadow: 2px 2px 5px #666;
                // backdrop-filter: blur(2px);
                .NavTitle{
                    width: 90%;
                    padding: 0 5%;
                    height: 40px;
                    display: flex;
                    background-color: orange;
                    justify-content: space-between;
                    color: black;
                    img{
                        padding: 10px 0;
                        width: 20px;
                        height: 20px;
                        filter: invert(60%);
                    }
                    img:hover{
                        filter: opacity(50%);

                    }
                }
                #MenuList{
                    opacity: 100;
                    width: 90%;
                    padding: 0 5%;
                    color: white;
                    .movelist{
                        color: white;
                        padding: 0;
                        li{
                            list-style: none;
                            height: 20px;
                            padding: 2.5px 0;
                            cursor: pointer;
                            text-decoration: none;
                        }
                        a{
                            text-decoration: none;
                        }
                        a:link{
                            color: white;
                        }
                        a:visited{
                            color: white;
                        }
                    }
                }
            }
      
        }
        #Sidebar{
            display: flex;
            flex:1;
            overflow: hidden;
            flex-direction: column;
        }
     
        .TitleText{
            line-height: 40px;
            padding: 0;
            margin: 0;
        }
        #nav{
            height: 40px;
            width: 90%;
            margin: 0px auto;
            display: flex;
            line-height: 30px;
            a{
                color: #fff;
                text-decoration: none;
            }
        }
        #EditSelect{
            overflow-x: auto;
            overflow-y: hidden;
            width: 90%;
            height: 40px;
            display: flex;
            font-size: 20px;
            line-height: 30px;
            list-style: none;
            // border-bottom: 1px solid #fff;
            padding: 0px 5%;
            margin: 0px auto;
            background-color: #222;
            flex-wrap: nowrap; 
            &::-webkit-scrollbar {
                width: 8px;
                height: 2px;
                // background: #ffffff;
              }
            &::-webkit-scrollbar-thumb {
            border-radius: 3.5px;
            background-color: #555;
            background-clip: padding-box;
            border: 1px solid transparent;
            &:hover {
                background-color: orangered;
            }
            }
            &::-webkit-scrollbar-track {
                background-color: #444;
            }
            &::-webkit-scrollbar-corner{

            }
            .sidebarNav{
                flex: 0 0 auto;
                margin-top: 5px;
                padding: 5px 10px 10px 10px;
                height: 20px;
                font-weight: bold;
                border-radius: 5px 5px 0px 0px;
                font-size: 16px;
            }
            .sidebarNav.select{
                background-color: #444;
            }
        } 
        #WidgetSet{
            flex: 1;
            overflow: scroll;
            padding: 10px 5%;
            padding-top: 10px;
            &::-webkit-scrollbar {
                width: 8px;
                height: 2px;
                // background: #ffffff;
              }
            &::-webkit-scrollbar-thumb {
            border-radius: 3.5px;
            background-color: #555;
            background-clip: padding-box;
            border: 1px solid transparent;
         
            }
            &:hover {
                background-color: orangered;
            }
            &::-webkit-scrollbar-track {
                background-color: #444;
            }
            &::-webkit-scrollbar-corner{

            }
        }
    }
    #MainEdit{
        flex: 8;
        height: 100vh;
        display: flex;
        flex-direction: column;
        #Screen{
        flex: 8;
        overflow: hidden;
        }
        #TemplateBar{
        flex: 2;
        background-color: #222;
        }
    }
}
.EditScreenListfalse{
    display: none !important;
}

#EditScreenList{
    width: 100%; 
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden; 
    height: 100%;

    .previewScreenSelect {
        background-color: #161616;
        //background-color: orange;
        border-radius: 0px 5px 5px 0px;
        .screenInfo{
            color: white;
            .screenTitle{
                color: white !important;
                border-bottom: 1px solid white !important;
            }
        }
        .screenView{
            // border: solid 3px orange;
        }
    }
    #ScreenList{
        box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.1), inset 0 5px 5px rgba(0, 0, 0, 0.05);
        margin: 0;
        padding: 0;
        flex: 1;
        background: #444;
        // height: 100%;
        overflow: scroll;
        &::-webkit-scrollbar {

            width: 8px;
            height: 2px;
            // background: #ffffff;
          }
        &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #666;
        //box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
        background-clip: padding-box;
        border: 1px solid transparent;
        &:hover {
            background-color: orange;
        }
        }
        &::-webkit-scrollbar-track {
            // background: #444;
        }
        .scrollList{

        }
    }
    #broadcastScreen{
        .screenView{
            // border: solid 3px red;
            box-sizing: border-box;
        }
        .broadcastScreenLive{
            // background-color: red;
            filter: opacity(100%);
            overflow: hidden;
            animation-name: onair;
            animation-duration: 2s;
            animation-direction:alternate;
            animation-iteration-count:infinite;
        }

        @keyframes onair {
            0% {
                filter: opacity(100%);
                // transform: scale(1.2);
            }
            50% {
                filter: opacity(50%);
                transform: scale(1);
            }
            100% {
                filter: opacity(100%);
                // transform: scale(1.2);
            }
          }
    }
    .previewScreen {
        list-style: none;
        margin: 0px auto;
        padding: 5px 5% 10px;
        width:90%;
        .screenInfo{
            font-family: '프리텐다드SemiBold';
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            padding-bottom: 5px; 
            .screenTitle{
                font-family: '프리텐다드SemiBold';
                font-size: 20px;
                width: 70%;
                background: none;
                color: #fff;
                border: none;
                border-bottom: 1px solid snow;
                outline:none;
                
            }
            input[type="button"]{
                font-family: '프리텐다드SemiBold';
                border-radius: 5px;
                border: none;
                outline:none;
            }
            .screenDelete{
                background-color: #F77397;
                color: #fff;
                font-size: 15px;
            }
        }
        .screenView:hover{
            // transform: scale(1.05);
            // box-shadow: 8px 8px 0px #222;
        }
        .screenView{
            position: relative;
            margin: 0px auto;
            height: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            border-radius: 5px;
            transition: transform 0.3s;
            box-shadow:  3.5px 3.5px 10px #252525,
            -3.5px -3.5px 10px #636363;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
            .wrap{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                // background-color: #fff;
                color: black;
                
            }
        }
    }
    #ScreenEditNav{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        #ScreenContol{
            margin-bottom: 5px;
            .ScreenEditButton{
                // width: 20px;
                height: 20px;
                margin: 5px 5px;
                img{
                    // display: flex;
                    // width: 20px;
                    height: 20px;
                }
            }
        }
        #ViewContol{
            width: calc(100% - 40px);
            background-color: #2C2C2C;
            border-radius: 10px;
            padding: 0px 10px ;
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            #selectPage{
                #ViewState{
                    display: flex;
                    #ViewPointerList{
                       
                        .ViewPointer{
                            padding: 0px 5px;
                            margin-left: 5px;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            // width: 16px;
                            height: 20px;
                            min-width: 20px;
                            box-sizing: border-box;
                            font-size: 16px;
                            line-height: 20px;
                            display: flex;
                            font-family: '프리텐다드Black';
                            background-color: red;
                            // box-shadow: 1.5px 1.5px 6px #252525, -1.5px -1.5px 6px #636363;
                        }
                        .ViewPointerOther{
                            padding: 2px;
                            margin-left: 5px;
                            border-radius: 5px;
                            // width: 16px;
                            height: 20px;
                            min-width: 20px;
                            box-sizing: border-box;
                            font-size: 12px;
                            line-height: 20px;
                            display: flex;
                            background-color: orange;
                            img{
                                height: 16px;
                                width: 16px;
                            }
                        }

                    }
                }
            }
            #selectView{
                width: 200px;
                position: relative;
                display: flex;
                justify-content: flex-end;
                #ViewSelectState{
                    font-size: 17px;
                    line-height: 17px;
                    text-align: end;
                    line-height: 17px;
                    display: flex;
                    .linkView{
                        white-space: nowrap;   
                        overflow: hidden;
                    }
                    .disableView{
                        color: #666;
                    }
                    .dropDownHint{
                        font-size: 10px;
                        margin-left: 5px;
                    }
                }
                .dropdown{
                    position: absolute;
                    right: -10px;
                    background-color: #333;
                    text-align: right;
                    margin: 10px 0 0 0;
                    max-height: 300px;
                    padding: 10px;
                    border-radius: 5px;
                    width: 200px;
                    overflow: auto;
                    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
                    z-index: 999;
                    li{
                        margin: 0px 2px 0px 10px;
                        padding: 5px 10px;
                        list-style: none;
                        border-bottom: 1px solid #666;
                    }
                    li:hover{
                        background-color: #666;
                    }
                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 2px;
                        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
                        // background: #ffffff;
                      }
                    &::-webkit-scrollbar-thumb {
                    border-radius: 3.5px;
                    background-color: #555;
                    background-clip: padding-box;
                    border: 1px solid transparent;
                    
                    &:hover {
                        background-color: orangered;
                    }
                    }
                    &::-webkit-scrollbar-track {
            
                    }
                }
            }
        }
    }
    // #screenAddSelect{
    //     display: flex;
    //     // flex-direction: column;

    //     height: 30px;
    //     margin: 5px 5%;
    //     .screenAdd{
    //         // width:50%;
    //         padding: 0px 5px;
    //         height: 30px;
    //         text-align: center;
    //         margin: 0px 1px;
    //         // border: 1px solid white;
    //         background-color: #444;
    //         border-radius: 2.5px;
    //         // box-shadow: 0 5px 5px rgba(20, 20, 20, 0.5), 0 3px 3px rgba(20, 20, 20, 0.3);
    //         display: flex;
    //         flex-wrap: wrap;
    //         justify-content: center;
    //         overflow: hidden;
    //         img{
    //             padding: 5px ;
    //             max-width : 30px; 
    //             height : 20px
    //         }
    //         p{
    //             margin: 0;
    //             padding: 0;
    //             line-height: 30px;
    //             font-weight: bold;
    //         }
    //         &:hover{
    //             background-color: #666;
    //         }
    //     }
    // }
    // #broadcastMenu{
    //     display: flex;
    //     // flex-direction: column;
    //     justify-content: space-between;
    //     height: 30px;
    //     background-color: #444;
    //     padding: 5px 5%;
    //     .flex{
    //         display: flex;
    //     }
    //     #broadcastStop{
    //         margin: 0px 5px;
    //         border-radius: 5px;
    //         padding: 5px ;
    //         background-color: #222;
    //         font-weight: bold;
    //     }
    //     #broadcastScreen{
    //         display: flex;
    //         border-radius: 5px;
    //         padding: 5px ;
    //         background-color: #222;
    //         box-shadow: 0 5px 5px rgba(20, 20, 20, 0.5), 0 3px 3px rgba(20, 20, 20, 0.3);  
    //         p{
    //             margin-left: 5px;
    //             font-weight: bold;
    //             font-size: 20px;
    //             line-height: 22px;
    //         }
    //     }
    //     #screenListTop{
    //         border-radius: 5px;
    //         padding: 5px ;
    //         background-color: #222;
    //         font-weight: bold;
    //     }
    // }

}

////////////////////////////////////////////////////////
// 사이드바 바텀 오버레이 
////////////////////////////////////////////////////////
#EditControlBox{
    border-radius: 10px 10px 0px 0px;
    // overflow: hidden;
    transition: height 0.4s;
    display: flex;
    flex-direction: column;
    // box-shadow: -5px -5px 5px rgba(0, 0, 0, 1);
    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.45), 0 -3px 3px rgba(0, 0, 0, 0.15);
    #OverlayTitle{
        width: 90;
        padding: 0 5%;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-weight: bold;
        background-color: orange;
        display: flex;
        justify-content: space-between;

        .titleArea{
            height: 40px;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
        }
        img{
            margin-right: 5px;
            width: 30px;
            height: 30px;
            object-fit: scale-down;
        }
        p{
            height: 30px;
            margin: 0;
            padding: 0;
            display: inline-block;
        }
        .updown{
            padding: 0px 5px;
        }
    }
    #WidgetOrderControlNav{
        display: flex;
        overflow: auto;
    }
    #WidgetOrderControlInfo{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items : center;


    }
    #WidgetOrderControlInfoText{
        flex-direction: column;
        display: flex;
        text-align: center;
        color: #adadad; /* Grey text color suitable for dark themes */
        // text-shadow: inset 0 5px 5px rgba(20, 20, 20, 0.45),inset 0 3px 3px rgba(20, 20, 20, 0.15); /* Text shadow to give an embossed, or "pushed in" effect */
        // font-family: Arial, sans-serif; /* You can change this to any preferred font */
        font-size: 18px; 
        
    }
    .widgetOrderList{
        flex: 1;
        width: 100%;
        padding: 0;
        background-color: #444;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 8px;
            height: 2px;
            // background: #ffffff;
          }
        &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: #555;
        background-clip: padding-box;
        border: 1px solid transparent;
        &:hover {
            background-color: orangered;
        }
        }
        &::-webkit-scrollbar-track {
            background-color: #444;
        }
        .handle{
            font-size: 20px;
            cursor: pointer;
        }
        
    }
    .WidgetOrder{
        background: #444;
        padding: 2px 5px;
        margin-left: 8px;
        border-bottom: 1px solid snow;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        line-height: 30px;
        .WidgetOrderInfo{
            display: flex;
            svg{
                width: 30px;
                height: 30px;
                object-fit: scale-down;
            }
            img{
                width: 30px;
                height: 30px;
                object-fit: scale-down;
            }
            p{
                margin: 0;
                margin-right: 10px;
                .alias{
                    font-weight: bold;
                }
                .uuid{
                    color: #ccc
                }
            }
            span{
                font-size: 14px;
            }
        }

    }
    .wigetSelet{
        background:#666;
    }
}
////////////////////////////////////////////////////////
// 편집화면
////////////////////////////////////////////////////////
#ScreenSize{
    // width: 100%;  
    // height: 100%;
    width: 80vw;
    height: 80vh;
    outline: none;
    // flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    #EditScreen{
        // padding-top: calc(100% / 16 * 9);
        // image-rendering: pixelated;
        background-color: #fff;
        position: absolute;
        color: black;
        transition: transform 0.5s;
        // overflow: hidden;
    }
    #EditScreen.NullPage{
        // padding-top: calc(100% / 16 * 9);
        // image-rendering: pixelated;
        background-color: transparent;
        position: absolute;
        color: black;
        transition: transform 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 80px;
            color: #adadad;
        }
        .Nullinfo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .NullinfoImage{
            display: flex;
        }
        img{
            margin: 20px;
            filter: brightness(0.7);
        }
        // overflow: hidden;
    }
    #editBackground{
        position: absolute;
        z-index: -999;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;
        .MediaBG{
            width: 100%;
            line-height: 100%;
            vertical-align: middle;
        }
    }
    .bgColor_white{
        background-color: white;
    }

    .bgColor_opacify{
        background-image: url(../assets/resource/dot.png);
        background-size: 20px 20px;
        opacity: 0.5;
    }

    .bgColor_black{
        background-color: black;
    }

    .bgColor_green{
        background-color: green;
    }

    // .bgColor_{
    //     background-color: white;
    // }
    
    .reversal{
        filter: invert(1);
    }
    
    #EditGuideLine{
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        pointer-events: none;
        .GuideLine{
            position: absolute;
            width: 100%;

        }
        .topLine{
            top:25%;
            stroke: rgb(50, 50, 50); 
            stroke-dasharray : 2,2;
            stroke-width: 2;
        }
        .midLine{
            top:50%;
            stroke: rgb(0, 0, 0); 
            stroke-dasharray : 5,5;
            stroke-width: 5;
        }
        .bottomLine{
            top:75%;
            stroke: rgb(50, 50, 50); 
            stroke-dasharray : 2,2;
            stroke-width: 2;
        }
        .leftLine{
            left:25%;
            stroke: rgb(50, 50, 50); 
            stroke-dasharray : 2,2;
            stroke-width: 2;
        }
        .centerLine{
            left:50%;
            stroke: rgb(0, 0, 0); 
            stroke-dasharray : 5,5;
            stroke-width: 5;
        }
        .rightLine{
            left:75%;
            stroke: rgb(50, 50, 50); 
            stroke-dasharray : 2,2;
            stroke-width: 2;
        }
        .cinemaBox{
            top:10%;
            left:10%;
            width: 80%;
            height: 80%;
            border : dotted 2px rgb(50, 50, 50); 
        }
        // background-color: red;
    }

    #GlobalOption{
        display: flex;
        width: 100%;
        height: 100%;
        // background-color: red;
        z-index: 20;
        pointer-events: none; 
        justify-content: flex-end;
        align-items: flex-start;

        #GlobalNav{
            display: flex;
            justify-content: flex-end;
            width: 100px;
            height: 40px;
            padding: 10px;

        }
    }
}

#EditNavHeader.ViewModetrue{
    position: absolute;
    width: 80vw;
    height: 80vh;
    top: 20%;
    // transition: top 0.5s height 0.5s;
    // background-color: red;
}
#EditNavHeader.ViewModefalse{
    position: absolute;
    width: 80vw;
    height: 20vh;
    top: 80%;
    transition: top 0.5s height 0.5s;

}

#EditNavHeader{
    background-color: #222;
    border-radius: 15px;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    button{
        background: none;
        color: white;
        border: 0;
        outline: 0;
    }
    #EditOverlapInfo{
        // box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2), 0 -3px 3px rgba(0, 0, 0, 0.1);
        // box-shadow:  0px -5px 20px rgba(0, 0, 0, 0.5);
        background-color: #555;
        line-height: 3vh;
        height: 3vh;
        display: flex;
        justify-content: space-between;
    }
    #WidgetSetting{
        display: flex;
        justify-content: space-between;
        margin: 0 0.5%;
        height: 5vh;
        #StateArea{
            display: flex;
            #bottombtn{
                height: 100%;
                display: flex;
            }
            button{
                font-family: '프리텐다드SemiBold';
                text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
                text-stroke: 0.6px; 
                width: 50px;
                margin: auto 3px;
                box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
                height: 80%;  
                border-radius: 2px;
                font-weight: bold;
                font-size: 20px;
            }
            .buttonImg{
                height: 80%;
                padding: 10%;
                object-fit: scale-down;
            }
            #autoSaving{
                background-color: orangered;
            }
            #saveError{
                background-color: red;
            }
            #saveErrorBox{
                z-index: 999;
                width: 280px;
                height: 80px;
                padding: 10px;
                top: -100px;
                border-radius:15px 15px 0px 0px;
                position: absolute;
                background-color: #222;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
            #saveinfoBox{
                z-index: 999;
                width: 280px;
                height: 80px;
                padding: 10px;
                top: -100px;
                border-radius:15px 15px 0px 0px;
                position: absolute;
                background-color: #222;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
            #autoSave{
                background-color: #60B177;
            }
            #ScreenSave{
                background-color: orange;
                &:hover{
                    background-color: orangered;
                }
            }
            #Undo{
              
            }
            #Undo.disable{
                background-color: #222;
                color: #666;
            }
            #Undo.able{
                background-color: orange;
                &:hover{
                    background-color: orangered;
                }
            }
            #Tips{
                background-color: #444;
                &:hover{
                    background-color: #666;
                }
            }
        }
        
        #WidgetArea{
            display: flex;
            justify-content: flex-end;
            height: 100%;
            margin-top: auto;
            #bottomNav{
                height: 100%;
                display: flex; 
                justify-content: flex-end;       
            }

            #widgetList{
                display: flex;
                background-color: #333;
                height: 80%;
                margin: auto 5px;
   
                border-radius: 50px;
                box-shadow: 0 5px 5px rgba(20, 20, 20, 0.45), 0 3px 3px rgba(20, 20, 20, 0.15);
                padding: 0px 10px;
                line-height: 30px;
                .widgetListImg{
                    height: 80%;
                    width: 35px;
                    margin: auto 3px;
                    object-fit: scale-down;
                }
                ul{
                    margin: auto 0px;
                    padding: 0 10px;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    list-style: none;
                    li{
                        // width: 30px;
                        margin: auto 10px;
                        text-align: center;
                        // font-weight: bold;
                    }
                    .unselect{
                        border-bottom: solid 1px #00000000;
                    }
                    .select{
                        font-weight: bold;
                        border-bottom: solid 1px white;
                    }
                }
            }
            #editSeting{
                display: flex;
                background-color: #333;
                box-shadow: 0 5px 5px rgba(20, 20, 20, 0.45), 0 3px 3px rgba(20, 20, 20, 0.15);
                height: 80%;
                margin: auto 5px;
                border-radius: 50px;
                padding: 0px 10px;
                line-height: 30px;
                .widgetListImg{
                    height: 80%;
                    width: 35px;
                    margin: auto 3px;
                    object-fit: scale-down;
                }
                ul{
                    margin: auto 0px;
                    padding: 0 10px;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    list-style: none;
                    li{
                        // width: 30px;
                        margin: auto 10px;
                        text-align: center;
                        // font-weight: bold;
                    }
                    .unselect{
                        border-bottom: solid 1px #00000000;
                    }
                    .select{
                        font-weight: bold;
                        border-bottom: solid 1px white;
                    }
                }
                
            }
            button{
                font-family: '프리텐다드SemiBold';
                text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
                text-stroke: 0.6px; 
                width: 50px;
                margin: auto 1.5px;
                height: 80%;  
                border-radius: 2px;
                font-weight: bold;
                font-size: 20px;
            }
            .buttonImg{
                height: 80%;
                margin: auto 10px;
                object-fit: scale-down;
            }
        }
        #WidgetKinds{
            height: 100%;
            padding: 0px;
            margin: 0px;
            font-size: 20px;
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            li{
                padding: 1vh 5px;
                text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
                text-stroke: 0.6px; 
            }
            .select{
                background-color: #333;
                border-radius: 2px 0px;
            }
        }
        #movebnt{
            font-size: 20px;
            img{
                object-fit: scale-down;
                height: 80%;
                padding: 10% 0;
                transition: transform 1s ;
            }
        }
        .expansion{
            transform: rotateX(180deg);
        }
        .contract{
            
        }
    }
    
    #WidgetPlace{
        flex: 1;
        overflow: auto;
        background-color: #333;
        // scroll-behavior: smooth;
        // transition-duration: 500ms;
        .WidgetPlaceDumi{
            width: 100%;
            height: 1vh;
        }
        &::-webkit-scrollbar {
            width: 8px;
            height: 2px;
            // background: #ffffff;
          }
        &::-webkit-scrollbar-thumb {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
        border-radius: 3.5px;
        background-color: #666;
        background-clip: padding-box;
        border: 1px solid transparent;
        &:hover {
            background-color: orangered;
        }
        }
        &::-webkit-scrollbar-track {
            background-color: #333;    
        }

        
        #EditScreenOption{
            display: flex;
            .disable{
                background-color: #444 !important;
            }
            .setList{
                margin: 0.5vh 0vh 0.5vh 0.5vh;
                padding: 0.5vh;
                background-color: #121212;
                border-radius: 2px;
                // width: 29vh;
                height: 10vh;
                .setTitle{
                    height: 2vh;
                    line-height: 2vh;
                    font-size: 1.5vh;
                    text-align: center;
                    // background-color: #222;
                }

                .setOption{
                    display: flex;
                    height: 8vh;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    li{
                        margin: 0.5vh;
                       .optionBox{
                            height: 5vh;
                            width: 5vh;
                            background-color: black;
                            border-radius: 5px;
                            margin: 0 auto;
                       }
                       .select{
                            background-color: orange;
                       }
                        img{
                            margin: 1vh;
                            height: 3vh;
                        }
                        p{
                            margin: 1vh 0px;
                            height: 2vh;
                            font-size: 1.2vh;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
#WidgetSelectField{
    
    #WidgetList{
        // image-rendering: pixelated;
        display: flex;
        max-height: 100%;
        flex-wrap: wrap;
        
        justify-content: flex-start;
        background-color: #333;

        .WidgetSelectContainer{

            width: 11vh; 
            height: 12vh;
        }
        .widgetSelectContent{
            display: flex;
            flex-direction: column ;
            margin: 1vh 0vh 1vh 1vh; 
            background-color: #121212;
            box-shadow: 0 5px 5px rgba(20, 20, 20, 0.45), 0 3px 3px rgba(20, 20, 20, 0.15);
            border-radius: 5px;
            width: 10vh;
            height: 10vh;
            .widgetSelectImg{
                margin: 1.5vh 2vh 0vh;
                width: 6vh;
                height: 6vh;
                display: flex;
            }
            .widgetSelectImg img{
                width: 98%;
                object-fit: scale-down;
                filter: brightness(100%);
            }
            .widgetSelectText{
                height: 2.5vh;    
                text-align: center;
                font-size: 1.2vh;
                line-height: 2.5vh;
                // padding-top: 0.5vh;
            }
            &:hover{
                background-color: #444;
                // box-shadow: 0 5px 5px rgba(20, 20, 20, 0.7), 0 3px 3px rgba(20, 20, 20, 0.2);
            }
        }
    }

}

////////////////////////////////////////////////////////
// 탬플릿 모달
////////////////////////////////////////////////////////
#EditOverlay{
    position: absolute;
    top:0;
}
#TemplateModalbg{
    position: absolute;
    background-color: #33333350;
    backdrop-filter: blur(3px);
    width: 100vw;
    height: 100vh;
}
#TemplateModal{
    width: 800px;
    height: 800px; 
    overflow: hidden;
    margin: 10vh auto;
    display: flex;
    flex-direction: column;
    background-color: #444;
    box-shadow: 2px 2px 5px black;
    
}
#TemplateHeader{
    display: flex;
    justify-content: space-between;
    background-color: orange;
}
#TemplateTitle{
    height: 40px;
    line-height: 40px;
    color: #fff;
    padding: 0px 5px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
}
#TemplateCategory{
    height: 30px;
    padding: 0 5px;
    line-height: 30px;
    background-color: #333;
    color: #fff;
}
ul#TemplateList{
    height: 730px;
    padding-bottom: 10px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
   
}
li.TemplateList{
    list-style: none;
    width: 180px;
    height: 180px;
    margin: 10px;
    background-color: #555;
    *{
        margin: 0;
        padding: 0;
        color: white;
    }
    .MiniView{
        list-style: none;
        width: 180px;
        height: 101px;
        overflow: hidden;
        object-fit: scale-down;
    }
    h3{
        width: 160px;
        margin: 0 10px;
        height: 25px;
        overflow: hidden;
    }
    p{
        width: 160px;
        margin: 0 10px;
        height: 40px;
        font-size: 15px;
        overflow: hidden;
    }
    
}
#TemplateObject{
    *{
        padding: 0;
        margin: 0;
    }
    #TemplateObjectHeader{
        background-color: #222;
        height: 160px;
        margin: 10px;
        border-radius: 10px;
        color: white;
        display: flex;
        #TemplateScreen{
            height: 160px;
            width: 284px;
            border-radius: 10px;
            overflow: hidden;
            background-color: white;
            border: solid 2px white;
            box-sizing: border-box;
        }
        dd{
            flex: 1;
            margin: 10px;
            dt{
                font-weight: bold;
                height: 40px;
                line-height: 40px;
                font-size: 30px;
                display: block;
                overflow: hidden;
            }
            dl{
                height: 100px;
            }
        
        }
    }
        ul{
            flex: 1;
            height: 570px;
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            grid-template-rows : 130px 130px 130px 130px ;
            row-gap: 10px;
            overflow: auto;
            li.TemplateScreenList{
                background-color: #555;
                border-radius: 5px;
                overflow: hidden;
                height: 130px;
                width: 180px;
                margin: 0px 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                *{
                    margin: 0;
                    padding: 0;
                    color: white;
                }
                .TemplateScreenSelect{
                    display: flex;
                }
                .TemplateScreenView{
                    width: 180px;
                    height: 101px;
                    border-radius: 5px;
                    overflow: hidden;
                }
                h3{
                    width: 160px;
                    margin: 0 10px;
                    height: 30px;
                    line-height: 30px;
                    overflow: hidden;
                }
                input[type="checkbox"] {
                    height: 20px;
                    width: 20px;
                    margin: 5px;
                    background: #fa3062;
                }
                p{
                    width: 160px;
                    margin: 0 10px;
                    height: 40px;
                    font-size: 15px;
                    overflow: hidden;
                }
            }
        }
}
////////////////////////////////////////////////////////
// 편집배경 변경 모달
////////////////////////////////////////////////////////
#EditBackgroundModalBG{
    position: absolute;
    background-color: #33333350;
    backdrop-filter: blur(3px);
    width: 100vw;
    height: 100vh;
}

#EditBackgroundModal{
    font-family: '프리텐다드SemiBold';
    width: 800px;
    height: 400px; 
    overflow: hidden;
    margin: 20vh auto;
    display: flex;
    flex-direction: column;
    background-color: #111;
    box-shadow: 2px 2px 5px black;
    .editBGTitle{
        background-color: #222;
        color: white;
        font-size: 30px;
        text-align: center;
        line-height: 50px;
        margin: 10px;
        width: 780px;
        height: 50px;
        border-radius: 5px;
    }
    .modalContent{
        margin: 10px;
        width: 780px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        justify-content: space-around;
        color: white;
        .viewSide{
            width: 50%;
            margin: 0;
            padding: 0;
            background-color: #111;
            display: flex;
            video{
                object-fit: cover;
                margin: auto;
                width: 348px;
                height: 216px;
                background-color: blue;
            }
            .preview{
                margin: auto;
                width: 348px;
                height: 216px;
            }
            .white{
                background-color: white;
            } 
            .black{
                background-color: black;
            }
            .green{
                background-color: green;
            }
            .opacify{
                background-image: url(../assets/resource/dot.png);
                background-size: 10px 10px;

            }
        }
        .selectSide{
            width: 50%;
            margin: 0;
            padding: 0;
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                li{
                    padding: 10px 10%;
                    margin: 10px 0px;
                    border-radius: 5px;
                    background-color: #222;
                }
            }
            .colorlist{
                display: flex;
                width: 100%;
                .colorSet{
                    margin: 10px 0px 10px 10px;
                    width: 30px;
                    height: 30px;
                    border: solid 2.5px #555;
                    image-rendering: pixelated;
                }
                .colorSelect{
                    border: solid 2.5px orange;
                }
                .white{
                    background-color: white;
                } 
                .black{
                    background-color: black;
                }
                .green{
                    background-color: green;
                }
                .opacify{
                    background-image: url(../assets/resource/dot.png);
                    background-size: 100% 100%;
                }
            }

            .warning{
                color: red;
            }
        }
    }
    .modalsubmit{
        // background-color: #222;
        margin: 10px;
        width: 780px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        // flex-direction: row-reverse;
        justify-content: space-between;
        input[type="button"]{
            width: 100px;
            border-radius: 5px;
            border: none;
            outline:none;
        }

    }
}
////////////////////////////////////////////////////////
// 편집화면 드레그 이벤트
////////////////////////////////////////////////////////
#dragImg{
    width: 0;
    height: 0;
    overflow: hidden;
}
#hiddenDragImg{
    opacity: 0;
}
#dragImg img{
    box-sizing: border-box;
}
#ShowScreenSize{
    width: 100%;
    height: 100vh;
}
.TemplateObject:focus {
    outline:none;
}
.overRay{
    // display: none;
    position: absolute;
    // background-color: red;
    // width: 100%;
    // height: 100%;
    top: 0%;
    border: solid 1px darkgray;
    box-sizing: border-box;
    cursor: move;
    #overRayRactLeftTop{
        cursor: nwse-resize;
        &:active{
            cursor: nwse-resize;   
        }
    }
    #overRayRactRightTop{
        cursor: nesw-resize;
    }
    #overRayRactLeftBottom{
        cursor: nesw-resize;
    }
    #overRayRactRightBottom{
        cursor: nwse-resize;
    }
    #overRayRactTop{
        cursor: ns-resize;
    }
    #overRayRactRight{
        cursor: ew-resize;
    }
    #overRayRactLeft{
        cursor: ew-resize;
    }
    #overRayRactBottom{
        cursor: ns-resize;
    }
    .overRayRact{
        position: absolute;
        width: 15px;
        height: 15px;
        border: solid 1px black;
        background-color: white;
        cursor: crosshair;
    }
    .widgetState{
        position: absolute;
        top : -8vmin;
        list-style: none;
        // margin: 20px;
        padding: 0;
        display: flex;
        li{
            box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
            display: flex;
            width: 5vmin;
            margin-left: 10px;
            height: 5vmin;
            border-radius: 5px;
            background-color: #444;
        }
    }
}

////////////////////////////////////////////////////////
// 인터렉티브 가이드
////////////////////////////////////////////////////////
#InteractivityGuide{
    color: white;
    display: flex;
    flex-direction: column;
    background-color: rgb(34, 34, 34);
    list-style: none;
    padding: 10px;
    box-shadow: 2px 2px 2px #000;
    // box-shadow: rgb(0 0 0) 2px 2px 2px;
    border-radius: 10px;
    margin: 0px;
    font-family: '프리텐다드SemiBold';
    font-weight: bold;
    width: 200px;
    img{
        max-width: 180px;
        height: auto;
        margin: 10px;
        border-radius: 5px;
    }
}

#TestNav{
    height: 50px;
    background-color: orangered;

}

// 메인스크린 추가 페이지 

.mianScreen{
    header {
        height: 80px;
        width: 100%;
    }
}
////////////////////////////////////////////////////////
// 새 페이지 추가 (템플릿에서 가저오기)
////////////////////////////////////////////////////////
#AppendTemplate{
    header {
        background-color: #333;
        display: flex;
        flex-direction: column;
        .headerTop{
            height: 40px;
            display: flex;
            justify-content: space-between;
            .headerMargin{
                width:200px ;
            }
            .title{
                height: 40px;
                margin: 0px;
                font-size: 15px;
                line-height: 40px;
                text-align: center;
            }
            .back{
                 background-color: #444;
                 height: 30px;
                 width: 70px;
                 margin: 5px 10px;
                 color:white;
                 &:hover{
                    background-color: #666;
                 }
            }
        }
        .headerBottom{
            height: 40px;
            padding: 0px 30px;
            background-color: #282828;
            .AppendTemplateNav{
                height: 40px;
                line-height: 40px;
                display: flex;
                justify-content: flex-start;
                .TemplateBnt{
                    height: 40px;
                    font-size: 13px;
                    text-align: center;
                    padding: 0px 10px;
                    box-sizing: border-box;
                    border-radius: 5px 5px 0px 0px;
                }
        
                .TemplateBnt.select{
                    // border-radius: 5px 5px 0px 0px;
                    background-color: #1c1c1c;
                    // border: 2px solid orange;
                    border-bottom: none;
                }

                .TemplateBnt.unselect{
                    // border-bottom: 2px solid orange;
                }
            }

        }

    }
    button{
        background-color: #333;
        border: none;
    }
    .templateMarket{
        display: flex;
        // border: 2px solid orange;
        border-top: none;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        height: calc(100vh - 40px);
        background-color: #1c1c1c;
        overflow: scroll;
        .searchField{
            margin: 10px 30px;
            .back{
                background-color: #333;
                height: 30px;
                width: 70px;
                border-radius: 5px;
                // margin: 5px 10px;
                color:white;
                &:hover{
                    background-color: #666;
                }   
           }
        }
        .templateField{
            transition: all 0.3s;
            &::-webkit-scrollbar {

                width: 8px;
                height: 2px;
                // background: #ffffff;
              }
            &::-webkit-scrollbar-thumb {
            border-radius: 3.5px;
            background-color: #666;
            //box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
            background-clip: padding-box;
            border: 1px solid transparent;
            &:hover {
                background-color: orange;
            }
            }
            &::-webkit-scrollbar-track {
                // background: #444;
            }
            .scrollList{
    
            }
        }
        .TemplateList{
            display: flex;
            // border-bottom: 1px solid #666;
            // border-top: 1px solid #666;
            background-color: #333;
            box-shadow: 5px 5px 5px  rgba(0,0,0,0.2); ;
            height: 130px;
            margin: 0px 30px 20px 30px;
            border-radius: 5px;
            transition: background-color 0.3s;
            &:hover{
                background-color: #666;
                box-shadow: 8px 8px 5px  rgba(0,0,0,0.2); ;   
            }
            .TemplateScreenView{
                margin: 7.5px;
                width: 204px;
                height: 114.75px;
                // border-radius: 5px;
                overflow: hidden;
                background-color: white;
            }
            .TemplateInfo{
                flex: 1;
                display: flex;
                justify-content: space-between;
                margin: 7.5px;
            }
            .TemplateProfile{
                flex: 1;
                h3{
                    font-size: 20px;
                    line-height: 20px;
                    margin: 10px 0px;
                }
                p{
                    font-size: 15px;
                    line-height: 20px;
                }
            }
            .TemplateDetail{
                width: 300px;
            }
        }
        .pageField{
            margin: 20px 30px;
            .pageFieldDetails{
  
                ul{
                    list-style: none;
                    display: flex;
                    padding: 0px;
                    margin: 0px;
                    li{
                        padding: 10px 10px;
                        margin: 0px;
                    }
                    li.select{
                        background-color: #444;
                    }
                }
                hr {
                    margin: 0px;
                    outline: none;
                    border: 1px solid #444;
                }
            }
            .templatePageList{
                display: flex;
                flex-direction: row;
                justify-content: start;
                flex-wrap: wrap;
                .PageList{
                    margin: 20px 20px 0px 0px;
                    width: 200px;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
           
                    .PageScreenView{
                        position: relative;
                        width: 200px;
                        height: 112.5px;
                        overflow: hidden;
                    }
                    &:hover{
                        .PageAppend{
                            opacity: 1;
                            background-color: #22222299;
                        }
                    }
                    .PageAppend{
                        position: absolute;
                        z-index: 1;
                        top:0px;
                        opacity: 0;
                        width: 200px;
                        height: 112.5px;
                        background-color: #22222200;
                        
                        transition: all 0.3s;
                        .AppendButton{
                            position: absolute;
                            width: 200px;
                            height: 112.5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            h3{
                                font-size: 30px;
                            }
                            button{
                                background-color: #333;
                                height: 30px;
                                width: 30px;
                                line-height: 30px;
                                margin-top: 5px;
                                margin-right: 5px;
                                display: flex;
                                text-align: center;
                                font-size: 15px;
                                border-radius: 5px;
                                // margin: 5px 10px;
                                color:white
                                &:hover{
                                    background-color: #666;
                                }   
                           }
                        }
                    }
                    .PageInfo{
                        width: 200px;
                        height: 87.5px;
                    
                        // background-color: blueviolet;
                        h3{
                            margin: 7.5px 0px;
                            height: 20px;
                            font-size: 16px;
                        }
                        p{
                            overflow: hidden;
                            font-size: 13px; 
                            line-height: 16px;
                            height: 47.5px; 
                        }
                    }
                }
                
            }
            .templatePageUserGuide{

                .UserGuide{
                    margin:20px 0px;

                    .h2{
                        width: 100%;
                        height: 50px;
                        background-color: #444;
                    }
                }
            }
        }
    }
}


#tet::placeholder{
    display: flex;
    justify-content: flex-start;
    width: calc( 100% - 20px );
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    color: #adadad;
    box-sizing: border-box;
    text-align: center;

    background-color: #2C2C2C;
    width: 100%;

    height: 100%;

    height: 50px;

    padding-top: 10px;
    margin-right: 10px;
    font-size: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: auto;
    
}
